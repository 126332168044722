
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';

import {
    CmsTopicAttribute, NameIdPair, Difficulty} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { Sorting } from '../util/sorting';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';

const URL_BASE_TOPIC_ATTRIBUTE = "api/attributes";

//interpolate between green and red

@Injectable()
export class TopicAttributeService extends AuthoringService {

    public difficulties: Difficulty[] = null;
    public allAttributes: CmsTopicAttribute[] = null;
    private isDirty = true;

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService
    ) {
        super(http, toastr, concurrencyService);
        this.getDifficulties();
        this.getAllAttributes();
    }

    public setDirty() {
        this.isDirty = true;
    }

    public getDifficulties(): Promise<Difficulty[]> {
        if (this.difficulties && !this.isDirty) {
            return Promise.resolve(this.difficulties);
        }
        return this.http.get<Difficulty[]>(`${URL_BASE_TOPIC_ATTRIBUTE}/difficulties/all`).pipe(
            map(diffs => Sorting.sortDifficulties(diffs)))
            .toPromise().then(diffs => {
                this.difficulties = diffs;
                this.isDirty = false;
                return diffs;
            });
    }

    public getAllAttributes(): Promise<CmsTopicAttribute[]> {
        if (this.allAttributes && !this.isDirty) {
            return Promise.resolve(this.allAttributes);
        }
        return this.http.get<CmsTopicAttribute[]>(`${URL_BASE_TOPIC_ATTRIBUTE}/all`).pipe(
            map(attrs => Sorting.sortAttributes(attrs)))
            .toPromise().then(attrs => {
                this.allAttributes = attrs;
                this.isDirty = false;
                return attrs;
            });
    }

    public getAttribute(id: string): Promise<CmsTopicAttribute> {
        return this.http.get<CmsTopicAttribute>(`${URL_BASE_TOPIC_ATTRIBUTE}/get/${id}`)
            .toPromise();
    }

    public saveAttribute(attribute: CmsTopicAttribute): Promise<void> {
        return this.http.post(`${URL_BASE_TOPIC_ATTRIBUTE}/save`, attribute)
            .toPromise().then(() => {
                this.isDirty = true;
                return this.getAllAttributes().then(() => {
                    return;
                });
            })
    }

    public deleteAttribute(id: string): Promise<void> {
        return this.http.delete(`${URL_BASE_TOPIC_ATTRIBUTE}/delete/${id}`)
            .toPromise().then(() => {
                this.isDirty = true;
                return this.getAllAttributes().then(() => {
                    return;
                });
            })
    }

    public getTopicReferences(attrId: string): Promise<NameIdPair[]> {
        return this.http.get<NameIdPair[]>(`${URL_BASE_TOPIC_ATTRIBUTE}/check-references/${attrId}`)
            .toPromise();
    }

    public refreshAttributes() {
        
    }
}

