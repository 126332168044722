<div class="randomized-algebra-preview">
    <div>
        <div class="akit-script-test container" #previewTable>
            <div class="preview-heading preview-row row">
                <div class="preview-heading-cell script-test-cell col-xs-6">
                    Exercise
                </div>
                <div class="preview-heading-cell script-test-cell col-xs-5">
                    Solution
                </div>
                <div class="preview-heading-cell script-test-cell col-xs-1">
                </div>
            </div>
            <div *ngFor="let item of specPreviews | async" class="preview-row row">
                <div class="cell-exercise script-test-cell cell-exercise-assignment col-xs-6">
                    <span class="katex-wrapper" [innerHTML]="item.instruction | safeHtml"></span>
                </div>
                <div class="cell-exercise script-test-cell cell-exercise-solution col-xs-5">
                    <span class="katex-wrapper" [innerHTML]="item.solution | safeHtml"></span>
                </div>
                <div class="cell-button script-test-cell col-xs-1">
                    <button class="katex-wrapper" type="button" class="fa fa-play" (click)="insertWidgets(item.viewSpec)"></button>
                </div>
            </div>
        </div>
    </div>

    <collapsable heading="Debug information" *ngIf="debug" [bgColor]="'rgba(0,0,0,0.10)'" [borderColor]="'darkgray'" [dividerColor]="'darkgray'"
        class="debug-collapsable">
        <debug-view *ngIf="debug" [debug]="debug"></debug-view>
    </collapsable>

    <div class="row akit-metadata-test" *ngIf="sessionId">
        <div class='col-sm-6 col-xs-12'>
            <cms-interaction [html]="interactionHTML" (newSessionId)="setRunSessionId($event)"></cms-interaction>
        </div>
        <div class='col-sm-6 col-xs-12'>
            <cms-solution [appId]='appId' [sessionId]='sessionId'></cms-solution>
        </div>
    </div>
</div>
<busy-gears *ngIf="busy"></busy-gears>