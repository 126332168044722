import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { compareVersion } from "../../authoring/util/sorting";
import { UserService } from "../../security/services/user.service";

@Injectable()
export class ContentVersionService {

    private contentVersion: string;
    private prodContentVersion: string;

    constructor(protected http: HttpClient,
        protected userService: UserService) { }

    public getContentVersion(): Promise<string> {
        return this.contentVersion
            ? Promise.resolve(this.contentVersion)
            : this.http.get('connect/content-version/', { responseType: 'text' })
                .toPromise()
                .then((contentVersion: string) => {
                    this.contentVersion = contentVersion;
                    return contentVersion
                })
    }

    public getProdContentVersion(): Promise<string> {
        return this.prodContentVersion
            ? Promise.resolve(this.prodContentVersion)
            : this.http.get('connect/prod-content-version/', { responseType: 'text' })
                .toPromise()
                .then((prodContentVersion: string) => {
                    this.prodContentVersion = prodContentVersion;
                    return prodContentVersion
                })
    }

    public isVersionMismatch() {
        let promises = [this.getContentVersion(), this.getProdContentVersion()];
        return Promise.all(promises).then(() => {
            return compareVersion(this.prodContentVersion, this.contentVersion) < 0;
        });
    }

    public showVersionAlert(force?: boolean) {
        if (!force && this.userService.getUser().hasRole("global.cms.skip-version-alert")) {
            return;
        }
        this.isVersionMismatch().then((mismatch) => {
            if (mismatch) {
                alert(`You are currently working with content version ${this.contentVersion}. The current content version on production is ${this.prodContentVersion}. Exercises saved with the current content version will not work on production yet!`);
            }
        });
    }
}
