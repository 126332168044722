import { IValidationReport, ValidationReport } from '../../authoring/types/ValidationReport';
import { IDebugInformation } from './algebrakit.type';

export interface I18NContent {
    [key: string]: string;
}

export interface I18NContentMap {
    [lang: string]: I18NContent;
}

export interface NameIdPair {
    name: string;
    id: string;
}

export interface AudienceMap {
    [id: string]: AudienceSpec
}

export interface AudienceSpec {
    audienceID: string;
    audienceType: string;
    languages: string[];
    country?: string;
    name?: string;
}

export interface AudienceResolveResult {
    baseAudienceId: string;
    courseId: string;
    audience: string;
    editor: string;
}

export interface EditorAssetResolveResult {
    courseId: string;
    formulaEditorId: string;
    assetId: string;
}

export enum EvaluationMode {
    EXACT, EQUIVALENT, EQUIVALENT_ENDLESS, EQUIVALENT_NO_DERIVATION
}

export enum RenderMode {
    RAW, PREPROCESSED
}

export enum PaletteType {
    virtualOnly,
    arithmetic,
    algebra,
    equations,
    advanced
}

export enum InstructionType {
    KEYWORD_EXPRESSION, SCRIPT
}

export enum QuestionMode {
    ONE_BY_ONE='ONE_BY_ONE', ALL_AT_ONCE='ALL_AT_ONCE'
}

export enum TableTaskSpec {
    COMPLETE_TABLE
}

export enum StepActionType {
    CALCULATE = "CALCULATE",
    WRITE = "WRITE",
    FIND = "FIND",
    GIVE = "GIVE"
}

export interface IResource {
    id: string;
    name: string;
    expr: string;
    inline?: boolean
}

export interface IResourceRef {
    id: string;
    name: string;
    referenceId: string;            //name of the reference (defined by author)
    referenceResourceId: string;    //uuid of resource in the referenced exercise
}

export enum IExerciseReferenceType {
    LIBRARY = 'LIBRARY', CLIENT_SPEC = 'CLIENT_SPEC'
}

export interface IExerciseReference {
    id: string;
    type: IExerciseReferenceType;
    exerciseId: string;
    definition?: IExerciseSpec
}


export interface IExerciseSpec {
    type: string;
    audience: string;
    scriptRef?: string;
    script?: string;
    virginHint?: string;
    translations?: I18NContentMap
    localVars?: LocalVariable[],
    course?: string;
}

export class LocalVariable {
    id: number;
    template: string;
    definition: string;
    boundVar: string;
    names: string[];
}

export enum ArithmeticTemplate {
    SUM, SUM_COLUMNWISE, SUM_SHORT,
    SUBTRACT, SUBTRACT_SHORT, SUBTRACT_COLUMNWISE,
    PRODUCT, PRODUCT_SHORT, PRODUCT_COLUMNWISE,
    DIVISION_COLUMNWISE
};

export enum ElementType {
    QUESTION = "QUESTION",
    CONTENT = "CONTENT"
}

export interface WKElement {
    mimeType: string;
    content: string;
}

export interface IExerciseView {
    type: string;
    i18n: string;
    showHints: boolean;
}

export class IStepView {
    id: string;
    type: string;
    solution?: WKElement[];
}

export class ViewSpecWrapper {
    view: IExerciseView;
    exerciseSpec: IExerciseSpec;
    debugInformation: IDebugInformation;
    success?: boolean;
    msg?: string;
    contentVersion: string;
}

export interface GenerateResponse {
    instances: ViewSpecWrapper[];
    validationReport: IValidationReport;
}

export interface ExerciseInfo {
    name: string,
    audienceId: string,
    exerciseType: string,
    numberOfLevels: number,
    interactionTypes: string[]
    randomizedSingleSpec?: boolean;
}