<akit-shortcuts (save)="saveExercise()" (run)="run()"></akit-shortcuts>
<div class='exercise-editor cms-navigator' *ngIf='exercise'>
    <form [formGroup]="form">
        <div class="panel-wrapper">
            <div class="row row-spacing">
                <div class="col-xs-9 col-lg-10">
                    <breadcrumb [readOnly]="true" [folder]="topic" [showChilds]="true">
                    </breadcrumb>
                </div>
                <div class='col-xs-3 col-lg-2'>
                    <h4 *ngIf="exercise.state == 'DRAFT'">DRAFT</h4>
                    <h4 *ngIf="exercise.tested">
                        <span class="fa fa-check"></span> Tested
                    </h4>
                </div>
            </div>
        </div>

        <div class="panel-wrapper">
            <akit-exercise-editor #specEditor *ngIf="exercise && i18nLabels" [attr.exercise]="JSON.stringify(exercise)"
                [attr.enable-metadata]="true" [attr.enable-basic-info]="true" [attr.enable-preview]="false"
                [attr.i18n-labels]="JSON.stringify(i18nLabels)" [attr.allow-experimental]="allowExperimental">
            </akit-exercise-editor>
        </div>

        <div class="panel-wrapper" *ngIf='topic'>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">Attributes</h3>
                </div>
                <div class="panel-body">
                    <div class='row row-spacing'>
                        <div class="col-xs-12">
                            <attribute-selector [attributes]="topic.attributeAssignments"
                                [selectedAttributes]='exercise.attributeAssignments'
                                (onChange)='attributesChanged($event.id)'></attribute-selector>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel-wrapper" *ngIf='topic'>
            <collapsable-panel [heading]="'References'">
                <busy-gears *ngIf="!references"></busy-gears>
                <reference-check *ngIf="references" [referencedBy]="references"></reference-check>
            </collapsable-panel>
        </div>

        <div class="panel panel-default panel-wrapper">
            <div class="panel-heading">
                <h3 class="panel-title" translate>Exercise tests</h3>
            </div>
            <div class="panel-body">
                <session-test (onSessionReplay)="onSessionReplay($event)" [testRefs]="testSessions"
                    [canCompare]="canCompareTestsessions()" [canUpdateSession]="canUpdateSession()"
                    [canUpdateTestCase]="canSetIsTestCase()">
                </session-test>
            </div>
        </div>

        <div class="panel panel-default test-panel" *ngIf="testSpec || wordSession">
            <div class="panel-heading">
                <h3 class="panel-title" translate>Test exercise</h3>
            </div>
            <div class="panel-body">
                <div class="row row-spacing">
                    <div class="col-sm-12">
                        <div *ngIf='!wordSession && testSpec'>
                            <div class="row" style="margin-bottom:20px;">
                                <div class='col-xs-10'>
                                    <audience-selector (onAudienceSelected)='setAudience($event)'
                                        [audience]='audience ? audience.baseAudienceId : null'></audience-selector>
                                </div>
                                <div class='col-xs-2'>
                                    <button type="button" (click)="generate(true)" [disabled]="!this.testSpec.audience">
                                        <span class="fa fa-refresh"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="row" style="margin-bottom:20px;" *ngIf="testHasError()">
                                <div class='col-xs-12'>
                                    <div>
                                        <br />
                                        <div class="alert alert-danger" [innerHTML]="errorMessage | nl2br : true">
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!testHasError()">
                                <div *ngIf="testSpec && audience">
                                    <div
                                        *ngIf="isSimpleExercise(testSpec) && testSpec.script;then testMultiple else testSingle">
                                        here
                                        is ignored</div>
                                    <ng-template #testMultiple>
                                        <randomized-algebra-preview [extendedErrors]="true" [exerciseSpec]="testSpec"
                                            [contentVersion]="contentVersion" [onlyEmitErrors]="true"
                                            (finished)="previewLoaded($event)" (newSessionId)="setRunSessionId($event)"
                                            (error)="onInteractionError($event)"
                                            (onErrorDetails)="setErrorMessage($event)"
                                            (debugInfo)="onDebugInfo($event)">
                                        </randomized-algebra-preview>
                                    </ng-template>
                                    <ng-template #testSingle>
                                        <div *ngIf="showSolution(testSpec); then testSolution else testNoSolution">here
                                            is
                                            ignored</div>
                                        <ng-template #testSolution>
                                            <div class="col-sm-6">
                                                <cms-interaction [exerciseSpec]="testSpec"
                                                    [contentVersion]="contentVersion" [appId]='"algebrakit-cms"'
                                                    [showErrors]="true" (newSessionId)="setRunSessionId($event)"
                                                    (debugInfo)="onDebugInfo($event)"></cms-interaction>
                                            </div>
                                            <div class="col-sm-6">
                                                <cms-solution [appId]='"algebrakit-cms"'
                                                    [sessionId]='runSessionId.sessionId' *ngIf="runSessionId">
                                                </cms-solution>
                                            </div>
                                        </ng-template>
                                        <ng-template #testNoSolution>
                                            <div class="col-sm-12">
                                                <cms-interaction [exerciseSpec]="testSpec"
                                                    [contentVersion]="contentVersion" [appId]='"algebrakit-cms"'
                                                    [showErrors]="true" (newSessionId)="setRunSessionId($event)"
                                                    (debugInfo)="onDebugInfo($event)"></cms-interaction>
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf='wordSession'>
                            <cms-calcbook [exerciseSpec]="testSpec" [appId]='"algebrakit-cms"' [sessionId]='wordSession'
                                (newSessionId)="setRunSessionId($event)"></cms-calcbook>
                        </div>
                    </div>
                </div>
                <div *ngIf="showStoreTestPanel()">
                    <div class="row row-spacing">
                        <div class="col-sm-12">
                            <h4>Store as unit test</h4>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-1">
                            <label>Name</label>
                        </div>
                        <div class="col-sm-6">
                            <input [formControl]='testNameControl' class='form-control'>
                        </div>
                        <div class="col-sm-3">
                            <label>
                                <input type="checkbox" [formControl]='isTestCaseCtrl'>
                                Store as test case
                            </label>
                        </div>
                        <div class="col-sm-2">
                            <button (click)="storeSession($event)" [disabled]="!sessionName"
                                class="btn btn-default btn-block">Store</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel-wrapper">
            <collapsable-panel [heading]="'Version information'">
                <ng-container *ngIf="lastPublishedVersion || (exercise && versionInfo) else noVersion">
                    <div *ngIf="lastPublishedVersion">
                        <div class="row row-spacing">
                            <div class="col-sm-2">
                                <label>Last published version</label>
                            </div>
                            <div class="col-sm-10">
                                <p>{{lastPublishedVersion.majorVersion}}.{{lastPublishedVersion.minorVersion}}
                                    <button class="btn btn-default" type="button"
                                        (click)="run(lastPublishedVersion.definition)" title="Run">
                                        <i class="fa fa-eye"></i>
                                        <span>Run</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <version-info-panel *ngIf="exercise && versionInfo" [state]="exercise.state"
                        [versionInfo]="versionInfo">
                    </version-info-panel>
                </ng-container>
                <ng-template #noVersion>
                    <div class="row row-spacing">
                        <div class="col-xs-12">
                            No version data available.
                        </div>
                    </div>
                </ng-template>
            </collapsable-panel>
        </div>

        <div class="panel panel-default update-panel" *ngIf="canEditTopicsScripts() && exercise.state != 'DRAFT'">
            <div class="panel-heading">
                <h3 class="panel-title">Update exercise</h3>
            </div>
            <div class="panel-body">
                <div class="row row-spacing">
                    <div *ngIf="showUpdateReferences && !(references && references.length === 0)">
                        <busy-gears *ngIf="!references"></busy-gears>
                        <div *ngIf="references" class="alert alert-warning">
                            <strong>Warning:</strong> performing a major update on this exercise template may change
                            the
                            following exercise arrangements and references:
                            <br />
                            <br />
                            <reference-check [referencedBy]="references"></reference-check>
                            <br /> Please only proceed if the updated template still matches the expected behaviour of
                            these
                            referenced exercises.
                        </div>
                    </div>
                    <div *ngIf="!isDefinitionValid" class="row row-spacing">
                        <div class="col-xs-12">
                            <div class="alert alert-warning">
                                The exercise contains errors. It might not work correctly.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-lg-1">
                        <label>Commit message</label>
                    </div>
                    <div class="col-sm-10 col-lg-11">
                        <textarea formControlName='commitMessage' class='form-control'
                            [(ngModel)]="exercise.commitMessage" required
                            [ngClass]="{'input-invalid': commitMessageControl.errors}"></textarea>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-sm-2 col-lg-1">
                        <label>Type of update</label>
                    </div>
                    <div class="col-sm-7 col-lg-8">
                        <select formControlName='updateType' class='form-control' [(ngModel)]="exercise.updateType"
                            (change)="updateTypeChanged()">
                            <option value="MINOR" [selected]="true">Minor</option>
                            <option value="MAJOR">Major</option>
                        </select>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <button class="btn btn-md btn-primary btn-block" type="button" (click)="commitExercise()"
                            [attr.title]="saveTitle"
                            [disabled]="canDeactivate() || !form.valid || !referenceCheckIsValid() || topicSelectorActive">
                            <i class="fa fa-save"></i>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <publish-confirm *ngIf="confirmPublish" class="panel-wrapper" (confirm)="doPublish()" (cancel)="cancelPublish()">
    </publish-confirm>

    <div style="margin-left:51px">
        <div class="row row-spacing">
            <div *ngIf="!canEditTopicsScripts(); else canEdit">
                <div class="col-xs-3">
                    <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()" title="OK">
                        <i class="fa fa-ban"></i>
                        <span>OK</span>
                    </button>
                </div>
                <div class="col-xs-3">
                    <button class="btn btn-md btn-default btn-block" type="button" (click)="run()"
                        [attr.title]="runTitle" [disabled]="!exerciseInitialised">
                        <i class="fa fa-eye"></i>
                        <span>Run</span>
                    </button>
                </div>
            </div>

            <ng-template #canEdit>
                <div *ngIf="changeCounter==0 && canDeactivate(); else noDeactivate">
                    <!-- no changes-->
                    <div class="col-xs-3" *ngIf="exercise.state == 'DRAFT' && !approveButtonVisible()">
                        <button class="btn btn-md btn-primary btn-block" type="button" [attr.title]="saveTitle"
                            (click)="commitExercise()" [disabled]="!exerciseInitialised">
                            <i class="fa fa-save"></i>
                            <span>Save</span>
                        </button>
                    </div>
                    <div class="col-xs-3" *ngIf="approveButtonVisible()">
                        <button class="btn btn-md btn-info btn-block" type="button" (click)="approve()"
                            title="Approve this exercise" [disabled]="exerciseInitialised == false">
                            <i class="fa fa-check"></i>
                            <span>Approve exercise template</span>
                        </button>
                    </div>
                    <div class="col-xs-3" *ngIf="exercise.state == 'APPROVED'">
                        <button class="btn btn-md btn-block" [ngClass]="exercise.tested ? 'btn-success' : 'btn-info'"
                            [disabled]="exercise.tested || (exerciseInitialised == false)" type="button"
                            [title]="!exercise.tested ? 'Mark this exercise as tested' : 'Current version is tested'"
                            (click)="setTested()">
                            <i class="fa fa-check"></i>
                            <span *ngIf="!exercise.tested">Confirm tested</span>
                            <span *ngIf="exercise.tested">Current version is tested</span>
                        </button>
                    </div>
                    <div class="col-xs-3" *ngIf="showPublishButton()">
                        <button class="btn btn-md btn-warning btn-block" type="button" (click)="publish()"
                            title="Publish this exercise" [disabled]="exerciseInitialised == false">
                            <i class="fa fa-leanpub"></i>
                            <span>Publish</span>
                        </button>
                    </div>
                </div>
                <ng-template #noDeactivate>
                    <div class="col-xs-3" *ngIf="exercise.state == 'DRAFT' && !approveButtonVisible()">
                        <button class="btn btn-md btn-primary btn-block" type="button" [attr.title]="saveTitle"
                            (click)="commitExercise()" [disabled]="!exerciseInitialised">
                            <i class="fa fa-save"></i>
                            <span>Save</span>
                        </button>
                    </div>
                </ng-template>
                <div class="col-xs-3">
                    <button class="btn btn-md btn-default btn-block" type="button" (click)="run()"
                        [attr.title]="runTitle" [disabled]="!isDefinitionValid || !exerciseInitialised">
                        <i class="fa fa-eye"></i>
                        <span>Run</span>
                    </button>
                </div>
                <div class="col-xs-3">
                    <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()"
                        title="Discard changes and close the editor">
                        <i class="fa fa-ban"></i>
                        <span>Close</span>
                    </button>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="modal fade interpretations-modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Confirm interpretation of symbols</h4>
                </div>
                <div class="modal-body">
                    <div *ngIf="interpretations">
                        <div class="interpration-item row" *ngFor="let interpretation of interpretations">
                            <div class="symbol-name col-xs-5">
                                <akit-latex [attr.latex]="interpretation.latex" [attr.display-mode]="false"></akit-latex>
                            </div>
                            <div class="symbol-type col-xs-5">{{interpretation.type}}</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="confirmInterpretation()">Confirm and add to symbols list</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>