import { Injectable }   from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CourseService } from './course.service';
import { CmsCourse } from '../types/navigator.types';

@Injectable()
export class CourseResolverService implements Resolve<CmsCourse> {
    constructor(private courseService: CourseService) {}
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsCourse> {
        let courseId = route.params['id'];
        return this.courseService.getCourse(courseId).toPromise();
    }
}


