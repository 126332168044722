import { Injectable }   from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CmsExerciseFilter, CmsExerciseReference } from '../types/navigator.types';
import { ExerciseService } from './exercise.service';
import { ToastrService } from 'ngx-toastr';
import { SetTitleService } from '../../app/services/set-title.service';
import { Title } from '@angular/platform-browser';
import { VersioningService } from './versioning.service';

@Injectable()
export class ExerciseReferenceResolverService extends SetTitleService implements Resolve<CmsExerciseReference> {
    constructor(private exerciseService: ExerciseService, private toastr: ToastrService, titleService: Title, private versioningService: VersioningService) { super(titleService) }
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsExerciseReference> {
        let id = route.params['id'];
        return this.exerciseService.lockResource(id).then(() => {
            return this.exerciseService.getExerciseReference(id).toPromise();
        })
        .then((ex) => {
            this.setTitle(ex.name)
            return ex;
        })
        .catch((error: any) => {
            this.versioningService.handleVersionMisMatch(error);
            this.exerciseService.showLockErrorMessage(error, true);
            throw new Error(error.message);
        });
    }
}

