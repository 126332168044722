import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Ng2CompleterModule } from 'ng2-completer';


import { BusyIconComponent } from './components/busy-icon/busy-icon.component';
import { AuthoringRoutingModule } from './authoring.routing';
import { AlgebraKITModule } from '../algebrakit/algebrakit.module';
import { TopicService } from './services/topic.service';
import { I18nLabelService } from './services/i18n-label.service';
import { ExerciseResolverService } from './services/exercise-resolver.service';
import { ExerciseFilterResolverService } from './services/exercise-filter-resolver.service';
import { CourseResolverService } from './services/course-resolver.service';
import { TopicNavigatorComponent } from './containers/topic-navigator/topic-navigator.component';
import { CourseNavigatorComponent } from './containers/course-navigator/course-navigator.component';
import { FolderComponent } from './components/folder/folder.component';
import { DebugViewComponent } from './components/debug-view/debug-view.component';
import { LeafComponent } from './components/leaf/leaf.component';
import { StackedTabsComponent, StackedTabComponent } from './components/stacked-tabs/stacked-tabs.component';
import { CourseOverviewComponent } from './containers/course-overview/course-overview.component';
import { TopicLeafComponent } from './components/topic-leaf/topic-leaf.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AttributeSelectorComponent } from './components/attribute-selector/attribute-selector.component';
import { TopicEditorComponent } from './containers/topic-editor/topic-editor.component';
import { SubjectEditorComponent } from './containers/subject-editor/subject-editor.component';
import { CourseEditorComponent } from './containers/course-editor/course-editor.component';
import { ExerciseEditorComponent } from './containers/exercise-editor/exercise-editor.component';
import { AttributeEditorComponent } from './components/attribute-assignment-editor/attribute-assignment-editor.component';
import { AudienceSelectorComponent } from './components/audience-selector/audience-selector.component';
import { I18nInputComponent } from './components/i18n-input/i18n-input.component';
import { I18nContentComponent } from './components/i18n-content/i18n-content.component';
import { I18nEditorComponent } from './components/i18n-editor/i18n-editor.component';
import { TestAlgebraComponent } from './containers/test-algebra/test-algebra.component';
import { RandomizedAlgebraPreviewComponent } from './components/randomized-algebra-preview/randomized-algebra-preview.component';
import { CollapsableComponent } from './components/collapsable/collapsable.component';
import { SpecCollapsableComponent } from './components/spec-collapsable/spec-collapsable.component';
import { HelpComponent } from './containers/help/help.component';
import { ReferenceComponent } from './containers/reference/reference.component';
import { SyntaxOverviewComponent } from './containers/syntax-overview/syntax-overview.component';
import { SyntaxRowComponent } from './components/syntax-row/syntax-row.component';
import { SyntaxUsageComponent } from './components/syntax-usage-example/syntax-usage-example.component';
import { SyntaxAssociativeComponent } from './components/syntax-associative-example/syntax-associative-example.component';
import { ModesOverviewComponent } from './containers/modes-overview/modes-overview.component';
import { ModesRowComponent } from './components/modes-row/modes-row.component';
import { ContactFormComponent } from './components/helpitem-contactform/helpitem-contactform.component';
import { HelpToolTipComponent } from './components/help-tooltip/help-tooltip.component';
import { HelpItemImgComponent } from './components/helpitem-img/helpitem-img.component';
import { HelpItemNoteComponent } from './components/helpitem-note/helpitem-note.component';
import { HelpItemLIDirective } from './directives/helpitem-listitem/helpitem-listitem.directive';

import { Safe } from './services/sanitizer';
import { ExerciseFilterEditorComponent } from './containers/exercise-filter-editor/exercise-filter-editor.component';
import { AttributeFilterToggleComponent } from './components/attribute-filter-toggle/attribute-filter-toggle.component';
import { ExercisePreviewComponent } from './components/exercise-preview/exercise-preview.component';
import { VersionOverviewComponent } from './containers/version-overview/version-overview.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DiffToolComponent } from './containers/diff-tool/diff-tool.component';
import { CourseService } from './services/course.service';
import { SubjectService } from './services/subject.service';
import { ExerciseScriptService } from './services/exercise-script.service';
import { ExerciseService } from './services/exercise.service';
import { PublishService } from './services/publish.service';
import { DiffService } from './services/diff.service';
import { DiffFindingsComponent } from './components/diff-findings/diff-findings.component';
import { ExerciseReferenceEditorComponent } from './containers/exercise-reference-editor/exercise-reference-editor.component';
import { ExerciseReferenceResolverService } from './services/exercise-reference-resolver.service';
import { ExerciseScriptPreviewComponent } from './components/exercise-script-preview/exercise-script-preview.component';
import { ClientExerciseSpecEditor } from './containers/exercise-client-spec-editor/exercise-client-spec-editor.component';
import { ClientExerciseSpecResolverService } from './services/client-exercise-spec-resolver.service';
import { ErrorJsonComponent } from './components/error-json/error-json.component';
import { MetadataEditorComponent } from './components/metadata-editor/metadata-editor.component';
import { MetadataCollectionEditorComponent } from './components/metadata-collection-editor/metadata-collection-editor.component';
import { MathDataEditorComponent } from './components/mathdata-editor/mathdata-editor.component';

import { ClipboardModule } from 'ngx-clipboard';
import { ExerciseReferenceExporter } from './components/exercise-reference-exporter/exercise-reference-exporter.component';
import { BusyGearsComponent } from './components/busy-gears/busy-gears.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { TopicLeafSearchComponent } from './components/topic-leaf-search/topic-leaf-search.component';
import { ExerciseClientSpecExporter } from './components/exercise-client-spec-exporter/exercise-client-spec-exporter.component';
import { LabelsOverviewComponent } from './containers/labels-overview/labels-overview.component';
import { LabelEditorComponent } from './containers/label-editor/label-editor.component';
import { VersioningService } from './services/versioning.service';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { VersionInfoPanelComponent } from './components/version-info-panel/version-info-panel.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { VersionViewerComponent } from './components/version-viewer/version-viewer.component';
import { VersionViewerContainer } from './containers/version-viewer-container/version-viewer-container.component';
import { DeleteCheckComponent } from './components/delete-check/delete-check.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { CanDeactivateGuard } from '../app/services/can-deactivate.guard.service';
import { AttrDeleteCheckComponent } from './components/attr-delete-check/attr-delete-check.component';
import { TopicAttributeService } from './services/topic-attribute.service.';
import { AttributesOverviewComponent } from './containers/attributes-overview/attributes-overview.component';
import { TopicAttributeEditorComponent } from './containers/topic-attribute-editor/topic-attribute-editor.component';
import { TruncatePipe } from './services/truncate.pipe';
import { TopicResolverService } from './services/topic-resolver.service';
import { SubjectResolverService } from './services/subject-resolver.service';
import { ReferenceListComponent } from './components/reference-list/reference-list.component';
import { ReferenceCheckComponent } from './components/reference-check/reference-check.component';
import { MoveExercisesComponent } from './components/move-exercise/move-exercise.component';
import { SubjectChangeCourse } from './components/subject-change-course/subject-change-course.component';
import { SearchService } from './services/search.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExerciseFilterWizardComponent } from './components/exercise-filter-wizard/exercise-filter-wizard.component';
import { ExerciseFilterListomponent } from './components/exercise-filter-list/exercise-filter-list.component';
import { ExerciseScriptBrowserComponent } from './components/exercise-script-browser/exercise-script-browser.component';
import { PublishSnapshotComponent } from './containers/publish-snapshot/publish-snapshot.component';
import { VerifyService } from './services/verify.service';
import { VerifyResultsComponent } from './components/verify-results/verify-results.component';
import { PublishConfrim } from './components/publish-confirm/publish-confirm.component';
import { IdSearchComponent } from './containers/id-search/id-search.component';
import { IdSearchLeaf } from './components/id-search-leaf/id-search-leaf.component';
import { ResourceRedirectorComponent } from './containers/resource-redirector/resource-redirector.component';
import { ResourceService } from './services/resource.service';
import { ModesInputComponent } from './components/modes-input/modes-input.component';
import { ModesService } from './services/modes.service';
import { CourseAudienceEditorComponent } from './components/course-audience-editor/course-audience-editor.component';
import { PublishSubjectComponent } from './containers/publish-subject/publish-subject.component';
import { ScrollContainerComponent } from './components/scroll-container/scroll-container.component';
import { AssetService } from './services/asset.service';
import { AssetEditorComponent } from './components/asset-editor/asset-editor.component';
import { SafeUrlPipe } from './util/safe-url.pipe';
import { AssetSelector } from './components/asset-selector/asset-selector.component';
import { MathDataDatasetEditorComponent } from './components/mathdata-entry-editors/mathdata-dataset-editor/mathdata-dataset-editor.component';
import { SessionTestComponent } from './components/session-test/session-test.component';
import { CmsTextEditorComponent } from '../algebrakit/components/cms-text-editor/cms-text-editor.component';
import { MoveFolderComponent } from './components/move-folder/move-folder.component';
import { MoveExerciseScriptsComponent } from './components/move-exercise-scripts/move-exercise-scripts.component';
import { CollapsablePanelComponent } from './components/collapsable-panel/collapsable-panel.component';
import { SymbolEditorWrapper } from './components/symbol-editor-wrapper/symbol-editor-wrapper';
import { PaginationControllerComponent } from './components/pagination-controller/pagination-controller.component';
import { KeyboardShortcutsModule }     from 'ng-keyboard-shortcuts';  
import { VersionMismatchComponent } from './containers/version-mismatch/version-mismatch.component';
import { AkitShortcutComponent } from './components/akit-shortcuts/akit-shortcuts.component';
import { TooltipModule } from '../app/components/common/ui/tooltip/tooltip.module';
import { CourseEditorAssetResolveComponent } from './components/course-fe-asset-resolve-editor/course-fe-asset-resolve-editor.component';
import { ValidationReportComponent } from './components/validation-report/validation-report';


@NgModule({
	imports: [AlgebraKITModule, BrowserModule, AuthoringRoutingModule, FormsModule, ReactiveFormsModule, Ng2CompleterModule,
		ClipboardModule, TruncateModule, NgxJsonViewerModule, Nl2BrPipeModule, NgSelectModule, TooltipModule, KeyboardShortcutsModule.forRoot()  ],
	declarations: [AttributeEditorComponent, BreadcrumbComponent, ExerciseEditorComponent,
		TestAlgebraComponent, TopicEditorComponent, FolderComponent, I18nContentComponent,
		TopicLeafComponent, TopicNavigatorComponent, AttributeSelectorComponent, I18nInputComponent,
		AudienceSelectorComponent, RandomizedAlgebraPreviewComponent, MathDataEditorComponent, MathDataDatasetEditorComponent,
		CourseNavigatorComponent, SubjectEditorComponent, ExerciseFilterEditorComponent, AttributeFilterToggleComponent,
		CourseEditorComponent, CourseOverviewComponent, LeafComponent, ExercisePreviewComponent, VersionOverviewComponent,
		I18nEditorComponent, CollapsableComponent, ProgressBarComponent, DiffToolComponent, DiffFindingsComponent, ExerciseScriptPreviewComponent,
		ClientExerciseSpecEditor, ErrorJsonComponent, MetadataEditorComponent, MetadataCollectionEditorComponent, ExerciseReferenceExporter,
		ExerciseReferenceEditorComponent, BusyIconComponent, Safe, 
		SearchFieldComponent, BusyGearsComponent, TopicLeafSearchComponent, ExerciseClientSpecExporter,
		LabelsOverviewComponent, LabelEditorComponent, VersionInfoPanelComponent, VersionViewerComponent, VersionViewerContainer, DeleteCheckComponent,
		HelpComponent, ReferenceComponent, SyntaxOverviewComponent, SyntaxRowComponent, SyntaxUsageComponent, SyntaxAssociativeComponent,
		ModesOverviewComponent, ModesRowComponent, HelpToolTipComponent, HelpItemImgComponent, HelpItemNoteComponent, HelpItemLIDirective, ContactFormComponent, DebugViewComponent,
		TruncatePipe, AttrDeleteCheckComponent, AttributesOverviewComponent, TopicAttributeEditorComponent,
		StackedTabComponent, StackedTabsComponent,
		ReferenceListComponent, ReferenceCheckComponent, MoveExercisesComponent, SubjectChangeCourse, ExerciseFilterWizardComponent, ExerciseFilterListomponent,
		IdSearchComponent, IdSearchLeaf, ResourceRedirectorComponent, ModesInputComponent,
		ExerciseScriptBrowserComponent, PublishSnapshotComponent, VerifyResultsComponent, PublishConfrim,
		CourseAudienceEditorComponent, PublishSubjectComponent, ScrollContainerComponent, AssetEditorComponent, SafeUrlPipe, 
		AssetSelector, SessionTestComponent, SpecCollapsableComponent, CourseEditorAssetResolveComponent,
		CmsTextEditorComponent, MoveFolderComponent, MoveExerciseScriptsComponent, AkitShortcutComponent,
		CollapsablePanelComponent, SymbolEditorWrapper, PaginationControllerComponent, VersionMismatchComponent,
		ValidationReportComponent],
	providers: [TopicService, ExerciseResolverService, ExerciseFilterResolverService, ExerciseReferenceResolverService, CourseResolverService, I18nLabelService,
		CourseService, SubjectService, ExerciseScriptService, ExerciseService, PublishService, 
		DiffService, ClientExerciseSpecResolverService, VersioningService, CanDeactivateGuard, TopicAttributeService, TopicResolverService,
		SubjectResolverService, SearchService, VerifyService, ResourceService, ModesService, AssetService],
	entryComponents: [SyntaxRowComponent, ModesRowComponent, SyntaxUsageComponent, SyntaxAssociativeComponent, StackedTabComponent],
	exports: [TopicNavigatorComponent, CollapsableComponent, BusyGearsComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA] //required to allow custom tags, such as <akit-exercise>
})
export class AuthoringModule { }



