import { Injectable } from '@angular/core';
import 'rxjs/Rx';

import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ResourceService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
    ) {
        super(http, toastr, concurrencyService);
    }

    public getResourceType(id: string): Promise<string> {
        return this.http.get(`api/resource/type/${id}`,  {responseType:'text'})
            .toPromise() as Promise<string>;
    }

    public copyAssets(oldId: string, newId: string) {
        return this.http.post(`api/resource/copy/assets/${oldId}/${newId}`, {})
            .toPromise();
    }
}