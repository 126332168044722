
import {map} from 'rxjs/operators';
import { AuthoringService } from "./authoring.service";
import { Injectable } from "@angular/core";
import { CmsVersioningInfo, CmsItem } from "../types/navigator.types";
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from "../../app/services/concurrency.service";
import { isArray } from "util";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

const URL_BASE_VERSIONING = "api/versioning";

@Injectable()
export class VersioningService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected router: Router
    ) {
        super(http, toastr, concurrencyService);
    }

    public setTestedTrue(id: string): Promise<any> {
        return this.http.post(`${URL_BASE_VERSIONING}/tested/${id}`, {})
            .toPromise()
            .catch(this._serverError);
    }

    public approve(id: string): Promise<CmsItem> {
        return this.http.post<CmsItem>(`${URL_BASE_VERSIONING}/approve/${id}`, {})
            .toPromise()
            .catch(this._serverError) as Promise<CmsItem>;
    }

    public setDeprecated(ids: string | string[]): Promise<any> {
        ids = isArray(ids) ? ids : [ids];
        return this.http.post(`${URL_BASE_VERSIONING}/set-deprecated/`, ids)
            .toPromise()
            .catch(this._serverError);
    }

    public approveCourseExercises(ids: string[]): Promise<CmsItem[]> {
        return this.http.post<CmsItem[]>(`${URL_BASE_VERSIONING}/approve-all`, ids)
            .toPromise()
            .catch(this._serverError) as Promise<CmsItem[]>;
    }

    public getLatestVersionInfoForAllExercisesInTopic(topicId: string): Promise<CmsVersioningInfo[] | any[]> {
        return this.http.get(`${URL_BASE_VERSIONING}/info/topic/${topicId}`).pipe(
            map((obj: any[]) => obj.map((o => this._getCmsVersionInfo(o)))))
            .toPromise()
            .catch(this._serverError);
    }

    public getLatestVersionInfoForAllExercisesInSubject(subjectId: string): Promise<CmsVersioningInfo[] | any[]> {
        return this.http.get(`${URL_BASE_VERSIONING}/info/subject/${subjectId}`).pipe(
            map((obj: any[]) => obj.map((o => this._getCmsVersionInfo(o)))))
            .toPromise()
            .catch(this._serverError);
    }

    public getLatestVersionInfo(id: string): Promise<CmsVersioningInfo | any> {
        return this.http.get(`${URL_BASE_VERSIONING}/info/${id}/latest`).pipe(
            map((obj: any) => obj ? this._getCmsVersionInfo(obj) : null))
            .toPromise()
            .catch(this._serverError);
    }

    public getVersionInfo(id: string, page: number, size: number): Promise<CmsVersioningInfo[] | any> {
        return this.http.get(`${URL_BASE_VERSIONING}/info/${id}/page/${page}/${size}`).pipe(
            map((obj: any[]) => {
                return obj.map(o => this._getCmsVersionInfo(o));
            }))
            .toPromise()
            .catch(this._serverError);
    }

    public getVersionData(id: string, major: number, minor: number): Promise<any> {
        ///api/versioning/data/pkessel1476625155262/2/4
        return this.http.get(`${URL_BASE_VERSIONING}/data/${id}/${major}/${minor}`)
            .toPromise()
            .catch(this._serverError);
    }

    public handleVersionMisMatch(error: any) {
        if (error.error) {
            error = error.error;
        }
        let type = error.type || error.errorType;
        if (type == "VERSION_MISMATCH_ERROR") {
            this.router.navigate(['/version-mismatch'], { queryParams: { resourceId: error.additionalInfo.resourceId } })
            throw error;
        }
    }

    private _getCmsVersionInfo(obj: any): CmsVersioningInfo {
        if (obj.majorVersion == undefined || obj.majorVersion == null ||
            obj.minorVersion == undefined || obj.minorVersion == null) {
        }
        return {
            majorVersion: obj.majorVersion,
            minorVersion: obj.minorVersion,
            created: new Date(obj.created),
            tested: obj.tested,
            editedBy: obj.editedBy,
            commitMessage: obj.commitMessage
        }
    }

}