<div class='course-editor cms-navigator' *ngIf="course">
    <h2>Edit course</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row row-spacing" *ngIf="canManageCourse()">
            <div class='col-xs-3 col-sm-2'>
                <label>ID</label>
            </div>
            <div class="col-xs-7 col-sm-5">
                <span>{{course.id}}</span>
            </div>
        </div>
        <div class='row row-spacing'>
            <div class='col-xs-4 col-sm-2'>
                <label>Namespace</label>
            </div>
            <div class="col-xs-8 col-sm-5" *ngIf="namespaces && namespaces.length > 0">
                <ng-container *ngIf="!this.course.namespace || this.course.namespace.length == 0 else namespaceSpan">
                    <ng-container *ngIf="isAdmin() else namespaceSelectSimple">
                        <ng-select (change)="ngSelectNamespaceChanged($event)" [items]="namespaces" [addTag]="true"
                            bindValue="id" bindLabel="name" placeholder="Specify a namespace for this course"
                            formControlName="namespace">
                        </ng-select>
                    </ng-container>
                    <ng-template #namespaceSelectSimple>
                        <select formControlName="namespace" class='form-control'>
                            <option value="" selected>Choose
                                a namespace</option>
                            <option *ngFor="let namespace of namespaces" value="{{namespace.id}}">
                                {{namespace.name}}
                            </option>
                        </select>
                    </ng-template>
                </ng-container>
                <ng-template #namespaceSpan>
                    <span class="form-control">
                        {{getNamespaceName(course.namespace)}}
                    </span>
                </ng-template>
            </div>
            <ng-container *ngIf="isAdmin() && (!this.course.namespace || this.course.namespace.length == 0)">
                <div class="col-xs-4 visible-xs"></div>
                <div class="col-xs-12 col-sm-5">
                    <label class="editor-input" for="showFreeAccountNamespaces">
                        <input type="checkbox" formControlName="showFreeAccountNamespaces"
                            id="showFreeAccountNamespaces" />
                        Also show namespaces for FREE accounts
                    </label>
                </div>
            </ng-container>
        </div>
        <div class='row row-spacing'>
            <div class='col-xs-4 col-sm-2'>
                <label>Name</label>
            </div>
            <div class="col-xs-8 col-sm-5">
                <input name='name' formControlName='name' class='form-control'
                    *ngIf="canManageCourse() else staticCourseName">
                <ng-template #staticCourseName>
                    <p *ngIf="!canManageCourse()">{{course.name}}</p>
                </ng-template>
            </div>
        </div>
        <ng-container *ngIf="editorAssetResolveList != null">
            <h3>Custom formula editors</h3>
            <div class="row" *ngIf="canManageCourse()">
                <div class="col-xs-6">
                    <div class="row">
                        <div class="col-xs-6">
                            <label>Name</label>
                        </div>
                        <div class="col-xs-6">
                            <label>Asset ID</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngFor="let entry of editorAssetResolveList; let index=index;">
                <ng-container *ngIf="canManageCourse()">
                    <course-fe-asset-resolve-editor class="col-xs-6" [editorAssetResolve]="entry"
                        (onChanged)="feResolveEntryChanged()"
                        [duplicateProps]="getDuplicateFeResolveProps(index)">
                    </course-fe-asset-resolve-editor>
                    <div class="col-xs-2 form-text">
                        <a class="button audience-edit-button" (click)="addFeResolveEntry(index)"
                            title="Add a formula editor">
                            <span class="fa fa-lg fa-plus"></span>
                        </a>
                        <a class="button audience-edit-button" (click)="removeFeResolveEntry(index)"
                            title="Delete this formula editor">
                            <span class="fa fa-lg fa-trash"></span>
                        </a>
                        <a *ngIf="index > 0" class="button audience-edit-button" (click)="moveFeResolveEntryUp(index)"
                            title="Move up">
                            <span class="fa fa-lg fa-arrow-up"></span>
                        </a>
                        <a *ngIf="index < editorAssetResolveList.length-1" class="button audience-edit-button"
                            (click)="moveFeResolveEntryDown(index)" title="Move down">
                            <span class="fa fa-lg fa-arrow-down"></span>
                        </a>
                    </div>
                </ng-container>
                <div class="col-xs-12" *ngIf="!canManageCourse()">
                    {{entry.formulaEditorId}}
                </div>
            </div>
            <div class="row" *ngIf="editorAssetResolveList.length == 0">
                <div class="col-xs-3" >
                    <button class="btn btn-default form-control" (click)="addFeResolveEntry()"><span class="fa fa-plus"></span> Add</button>
                </div>
            </div>
            <br />
        </ng-container>
        <h3>Audiences</h3>
        <div class="row" *ngIf="canManageCourse()">
            <div class="col-xs-10">
                <div class="row">
                    <div class="col-xs-3">
                        <label>Name</label>
                    </div>
                    <div class="col-xs-3">
                        <label>Base Audience</label>
                    </div>
                    <div class="col-xs-3">
                        <label>Resolve to</label>
                    </div>
                    <div class="col-xs-3">
                        <label>Editor</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngFor="let audience of course.audiences; let index=index;">
            <course-audience-editor class="col-xs-10" [audience]="audience" *ngIf="canManageCourse()"
                [duplicateProps]="getDuplicateProps(index)" [customPalettes]="customPalettes">
            </course-audience-editor>
            <div class="col-xs-2 form-text" *ngIf="canManageCourse()">
                <a class="button audience-edit-button" (click)="addAudience(index)" title="Add an attribute">
                    <span class="fa fa-lg fa-plus"></span>
                </a>
                <a *ngIf="course.audiences?.length > 1" class="button audience-edit-button"
                    (click)="removeAudience(index)" title="Delete this attribute">
                    <span class="fa fa-lg fa-trash"></span>
                </a>
                <a *ngIf="index > 0" class="button audience-edit-button" (click)="moveAudienceUp(index)"
                    title="Move up">
                    <span class="fa fa-lg fa-arrow-up"></span>
                </a>
                <a *ngIf="index < course.audiences.length-1" class="button audience-edit-button"
                    (click)="moveAudienceDown(index)" title="Move down">
                    <span class="fa fa-lg fa-arrow-down"></span>
                </a>
            </div>
            <div class="col-xs-12" *ngIf="!canManageCourse()">
                {{audience.name}}
            </div>
        </div>
        <br />
        <div class='row row-spacing' *ngIf="canManageCourse() || canManageFreeCourse()">
            <div class='col-xs-4 col-sm-2'>
                <label>
                    <ng-container *ngIf="canManageCourse()">
                        Add preselected audiences for
                    </ng-container>
                    <ng-container *ngIf="canManageFreeCourse()">
                        Audience region
                    </ng-container>
                </label>
            </div>
            <div class="col-xs-4 col-sm-2">
                <select formControlName='preselectRegion' class='form-control'>
                    <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                </select>
            </div>
            <div class="col-xs-4 col-sm-3">
                <button class="btn btn-default" type="button" (click)="addPreselectedAudiences()">
                    <ng-container *ngIf="canManageCourse()">
                        <span class="fa fa-lg fa-plus"></span>
                        Add
                    </ng-container>
                    <ng-container *ngIf="canManageFreeCourse()">
                        Set
                    </ng-container>
                </button>
            </div>
        </div>
        <h3>Options</h3>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-4 col-sm-2 col-md-3'>
                <label class="editor-input">Default formula editor</label>
            </div>
            <div class="col-xs-4 col-sm-2">
                <select formControlName='defaultEditor' class='form-control'>
                    <option [value]="null"></option>
                    <option *ngFor="let palette of allPalettes" [value]="palette.id">{{palette.name}}</option>
                </select>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="applyEditorToChilds">
                    <input type="checkbox" formControlName="applyEditorToChilds" id="applyEditorToChilds" />
                    Apply to all child subjects
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteEditorInDraftChilds">
                    <input type="checkbox" formControlName="overwriteEditorInDraftChilds"
                        id="overwriteEditorInDraftChilds" />
                    Overwrite in all DRAFT child exercises <span *ngIf="applyEditorToChilds">recursively</span>
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteEditorInApprovedChilds">
                    <input type="checkbox" formControlName="overwriteEditorInApprovedChilds"
                        id="overwriteEditorInApprovedChilds" />
                    Overwrite in all APPROVED child exercises <span *ngIf="applyEditorToChilds">recursively</span>
                </label>
            </div>
        </div>
        <div class="row row-spacing">
            <hr />
        </div>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-4 col-sm-2 col-md-3'>
                <label class="editor-input">Default question mode</label>
            </div>
            <div class="col-xs-4 col-sm-2">
                <select formControlName='defaultQuestionMode' class='form-control'>
                    <option [value]="null"></option>
                    <option *ngFor="let questionMode of questionModes" [value]="questionMode.id">{{questionMode.name}}
                    </option>
                </select>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="applyQuestionModeToChilds">
                    <input type="checkbox" formControlName="applyQuestionModeToChilds" id="applyQuestionModeToChilds" />
                    Apply to all child subjects
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteQuestionModeInDraftChilds">
                    <input type="checkbox" formControlName="overwriteQuestionModeInDraftChilds"
                        id="overwriteQuestionModeInDraftChilds" />
                    Overwrite in all DRAFT child exercises <span *ngIf="applyQuestionModeToChilds">recursively</span>
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteQuestionModeInApprovedChilds">
                    <input type="checkbox" formControlName="overwriteQuestionModeInApprovedChilds"
                        id="overwriteQuestionModeInApprovedChilds" />
                    Overwrite in all APPROVED child exercises <span *ngIf="applyQuestionModeToChilds">recursively</span>
                </label>
            </div>
        </div>
        <div class="row row-spacing">
            <hr />
        </div>
        <div class='row row-spacing' *ngIf="rootSubject && course">
            <div class='col-xs-4 col-sm-2 col-md-3'>
                <label class="editor-input">Default audience</label>
            </div>
            <div class="col-xs-4 col-sm-2">
                <select formControlName='defaultAudience' class='form-control'>
                    <option [value]="null"></option>
                    <option *ngFor="let audience of course.audiences" [value]="audience.baseAudienceId">
                        {{audience.name || audience.baseAudienceId}}
                    </option>
                </select>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="applyAudienceToChilds">
                    <input type="checkbox" formControlName="applyAudienceToChilds" id="applyAudienceToChilds" />
                    Apply to all child subjects
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteAudienceInDraftChilds">
                    <input type="checkbox" formControlName="overwriteAudienceInDraftChilds"
                        id="overwriteAudienceInDraftChilds" />
                    Overwrite in all DRAFT child exercises <span *ngIf="applyAudienceToChilds">recursively</span>
                </label>
            </div>
        </div>
        <div class='row row-spacing' *ngIf="rootSubject">
            <div class='col-xs-8 col-sm-4 col-md-5'>
            </div>
            <div class="col-xs-4 col-sm-8 col-md-7">
                <label class="editor-input" for="overwriteAudienceInApprovedChilds">
                    <input type="checkbox" formControlName="overwriteAudienceInApprovedChilds"
                        id="overwriteAudienceInApprovedChilds" />
                    Overwrite in all APPROVED child exercises <span *ngIf="applyAudienceToChilds">recursively</span>
                </label>
            </div>
        </div>
        <div class="row row-spacing">
            <hr />
        </div>
        <ng-container *ngIf="canManageCourse()">
            <div class='row row-spacing' *ngIf="rootSubject && course">
                <div class='col-xs-4 col-sm-2'>
                    <label class="editor-input">Scoring model</label>
                </div>
                <div class="col-xs-4 col-sm-2">
                    <input name='scoringModel' formControlName='scoringModel' class='form-control'>
                </div>
            </div>
            <div class='row row-spacing' *ngIf="rootSubject && course">
                <div class='col-xs-4 col-sm-2'>
                    <label class="editor-input">Theme</label>
                </div>
                <div class="col-xs-4 col-sm-2">
                    <input name='theme' formControlName='theme' class='form-control'>
                </div>
            </div>
            <div class='row row-spacing' *ngIf="rootSubject && course && isAdmin()">
                <div class='col-xs-4 col-sm-2'>
                    <label class="editor-input">Blacklisted interactions</label>
                </div>
                <div class="col-xs-4 col-sm-4">
                    <input name='theme' formControlName='blacklist' class='form-control'>
                </div>
            </div>
        </ng-container>
    </form>

    <div class="row button-row">
        <div class="col-xs-3">
            <button class="btn btn-md btn-primary btn-block" type="button" title="OK" (click)="onSubmit()"
                [disabled]="!form.valid || !audienceValid() || !feAssetResolveListValid()">
                <i class="fa fa-save"></i>
                <span>OK</span>
            </button>
        </div>
        <div class="col-xs-3">
            <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()" title="Cancel">
                <i class="fa fa-ban"></i>
                <span translate>Close</span>
            </button>
        </div>
    </div>

    <div class="modal fade confirmation-modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="gears-container" *ngIf="busy">
                    <akit-busy-gears></akit-busy-gears>
                </div>
                <ng-container *ngIf="!busy">
                    <div class="modal-header modal-danger-bg">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title"><span class="fa fa-warning"></span> Confirm overwriting properties in
                            child exercises</h4>
                    </div>
                    <div class="modal-body modal-danger-bg">
                        You have indicated you want to execute the following actions:
                        <ul>
                            <li *ngFor="let msg of overwriteNotifications" [innerHTML]="msg"></li>
                        </ul>
                        Are you sure you want to continue?
                    </div>
                    <div class="modal-footer modal-danger-bg">
                        <button type="button" class="btn btn-danger" (click)="confirmSubmit()">Confirm</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>