<akit-shortcuts (save)="commit()" (run)="run()"></akit-shortcuts>
<div class="exercise-filter-editor cms-navigator">
    <ng-container *ngIf="!hasExerciseReferences else unsupportedReferences">
        <div class="panel-wrapper">
            <div class="row row-spacing">
                <div class="col-xs-7 col-lg-9">
                    <breadcrumb [readOnly]="true" [courseName]="course?.name" [folder]="subject"
                        (parentChange)='setSubject($event)' [showChilds]="true">
                    </breadcrumb>
                </div>
                <div class='col-xs-2 col-lg-1'>
                    <h4 *ngIf="exercise.state == 'DRAFT'">DRAFT</h4>
                    <h4 *ngIf="exercise.tested">
                        <span class="fa fa-check"></span> Tested
                    </h4>
                </div>
                <div class="col-xs-3 col-lg-2" style="text-align: right" *ngIf="issueCopyCourse && issueCopySubject">
                    <button (click)="showIssueCopyPanel(true)" class="btn btn-default">Copy to issue course</button>
                </div>
            </div>
        </div>

        <div class="panel-wrapper" *ngIf="issueCopyCourse && issueCopySubject">
            <!-- <div class="row row-spacing">
                <div class="col-xs-12">
                    <button (click)="showIssueCopyPanel(true)" class="btn btn-default">Copy to issue
                        course</button>
                </div>
            </div> -->
            <ng-container *ngIf="issueCopyPanelVisible">
                <div class="row row-spacing">
                    <div class="col-xs-7 col-lg-9">
                        <breadcrumb [courseName]="issueCopyCourse.name" [folder]="issueCopySubject"
                            (parentChange)='setIssueSubject($event)' [showChilds]="true">
                        </breadcrumb>
                    </div>
                    <div class='col-xs-5 col-lg-3'>
                        <input [(ngModel)]='issuePrefix' placeholder="Issue prefix" class='form-control'>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-xs-12">
                        <button (click)="copyToIsseCourse()" class="btn btn-primary">Confirm</button>
                        <button (click)="showIssueCopyPanel(false)" class="btn btn-default">Cancel</button>
                    </div>
                </div>
            </ng-container>

        </div>

        <div class="panel-wrapper">
            <akit-exercise-editor #specEditor
                *ngIf="exercise && course && subject && audienceLangMap && audiences && availablePalettes"
                [attr.exercise]="JSON.stringify(exercise)" [attr.enable-metadata]="true" [attr.enable-basic-info]="true"
                [attr.audiences]="JSON.stringify(audiencesForSpecEditor)"
                [attr.resolve-audiences]="JSON.stringify(resolveAudiencesForSpecEditor)" [attr.enable-preview]="false"
                [attr.i18n-labels]="JSON.stringify(i18nLabels)"
                [attr.interaction-blacklist]="interactionBlacklist ? JSON.stringify(interactionBlacklist) : null"
                [attr.default-question-mode]="subject.defaultQuestionMode"
                [attr.default-audience]="subject.defaultAudience" [attr.default-editor]="subject.defaultEditor"
                [attr.available-palettes]="JSON.stringify(availablePalettes)"
                [attr.allow-experimental]="allowExperimental">
            </akit-exercise-editor>
        </div>

        <spec-collapsable *ngIf="validationReport" #validationCollapsable>
            <span class="sc-summary">
                Validation Results:
                <i *ngIf="validationReport.itemValidationStatus === 'PASSED' else validationFailed"
                    class="fa fa-check"></i>
                <ng-template #validationFailed>
                    <ng-container *ngIf="validationReport.numberOfFailed > 0">
                        <i class="fa fa-times-circle"></i> {{validationReport.numberOfFailed}}
                    </ng-container>
                    <ng-container *ngIf="validationReport.numberOfUnavailable > 0">
                        <i class="fa fa-exclamation-triangle"></i> {{validationReport.numberOfUnavailable}}
                    </ng-container>
                    <ng-container *ngIf="validationReport.numberOfPassed > 0">
                        <i class="fa fa-check"></i> {{validationReport.numberOfPassed}}
                    </ng-container>
                </ng-template>
            </span>
            <validation-report class="sc-content" [validationReport]="validationReport"></validation-report>
        </spec-collapsable>

        <spec-collapsable *ngIf="testSessions && testSessions.length>0">
            <span class="sc-summary">Exercise tests</span>
            <session-test class="sc-content" [testRefs]="testSessions" (onSessionReplay)="onSessionReplay()"
                (onSessionsDirty)="setLocalTestSessionArray()" [canCompare]="canCompareTestsessions()"
                [canUpdateSession]="canUpdateSession()" [canUpdateTestCase]="canSetIsTestCase()">
            </session-test>
        </spec-collapsable>

        <spec-collapsable *ngIf="lastPublishedVersion || (exercise && versionInfo)">
            <span class="sc-summary">Version information</span>
            <div class="sc-content">
                <div *ngIf="lastPublishedVersion">
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Last published version</label>
                        </div>
                        <div class="col-sm-10">
                            <p>{{lastPublishedVersion.majorVersion}}.{{lastPublishedVersion.minorVersion}}
                                <button class="btn btn-default" type="button"
                                    (click)="run(lastPublishedVersion.definition)" title="Run">
                                    <i class="fa fa-eye"></i>
                                    <span>Run</span>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                <version-info-panel *ngIf="exercise && versionInfo" [state]="exercise.state"
                    [versionInfo]="versionInfo">
                </version-info-panel>
                <ng-template #noVersion>
                    <div class="row row-spacing">
                        <div class="col-xs-12">
                            No version data available.
                        </div>
                    </div>
                </ng-template>
            </div>
        </spec-collapsable>

        <form [formGroup]="form">
            <div class="panel panel-default test-panel" *ngIf="testSpec || testSessionId || validationReport">
                <div class="panel-heading">
                    <h3 class="panel-title" translate>Test exercise</h3>
                </div>
                <div class="panel-body">
                    <ng-container *ngIf="testSpec || testSessionId">
                        <div *ngIf="errorMessage && errorMessage.length > 0">
                            <br />
                            <div class="alert alert-danger" [innerHTML]="errorMessage | nl2br : true">
                            </div>
                            <br />
                        </div>
                        <div class="row" *ngIf='testSpec && !testHasError()' [hidden]="hasUnconfirmedInterpretations">
                            <div
                                *ngIf="isSimpleExercise(testSpec) && isScriptedExercise(testSpec);then testMultiple else testSingle">
                                here
                                is ignored</div>
                            <ng-template #testMultiple>
                                <div class="col-sm-12">
                                    <randomized-algebra-preview [exerciseSpec]="testSpec"
                                        [contentVersion]="contentVersion" [scoringModel]="course?.options?.scoringModel"
                                        [extendedErrors]="true" (newSessionId)="setRunSessionId($event)"
                                        [onlyEmitErrors]="true" (finished)="previewLoaded($event)"
                                        (onErrorDetails)="setErrorMessage($event)" (debugInfo)="onDebugInfo($event)"
                                        (error)="onInteractionError($event)"
                                        (validationReport)="onValidationReport($event)">
                                    </randomized-algebra-preview>
                                </div>
                            </ng-template>
                            <ng-template #testSingle>
                                <div *ngIf="showSolution(testSpec); then testSolution else testNoSolution">here is
                                    ignored</div>
                                <ng-template #testSolution>
                                    <div class="col-sm-6">
                                        <cms-interaction [exerciseSpec]="testSpec" [contentVersion]="contentVersion"
                                            [cycle]="testSpecCycle" [appId]='"algebrakit-cms"' [showErrors]="true"
                                            [scoringModel]="course?.options?.scoringModel"
                                            (newSessionId)="setRunSessionId($event)" (debugInfo)="onDebugInfo($event)"
                                            [showErrors]="true" (error)="onInteractionError($event)"
                                            (validationReport)="onValidationReport($event)"></cms-interaction>
                                    </div>
                                    <div class="col-sm-6">
                                        <cms-solution [appId]='"algebrakit-cms"' [sessionId]='runSessionId.sessionId'
                                            *ngIf="runSessionId" (error)="onInteractionError($event)"></cms-solution>
                                    </div>
                                </ng-template>
                                <ng-template #testNoSolution>
                                    <div class="col-sm-12">
                                        <cms-interaction [exerciseSpec]="testSpec" [contentVersion]="contentVersion"
                                            [cycle]="testSpecCycle" [appId]='"algebrakit-cms"' [showErrors]="true"
                                            [scoringModel]="course?.options?.scoringModel"
                                            (newSessionId)="setRunSessionId($event)" (debugInfo)="onDebugInfo($event)"
                                            (error)="onInteractionError($event)"
                                            (validationReport)="onValidationReport($event)">
                                        </cms-interaction>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="!awaitingInterpretations && hasUnconfirmedInterpretations">
                            <i class="fa fa-times-circle" aria-hidden="true"></i> The exercise contains symbols that
                            have not been defined in the "Symbols" tab. Add them there to continue with the exercise.
                        </div>
                        <div class="row row-spacing" *ngIf='testSessionId'>
                            <cms-exercise [sessionId]="testSessionId" [appId]='"algebrakit-cms"'></cms-exercise>
                        </div>
                        <div *ngIf="runSessionId">
                            <div class="row row-spacing">
                                <div class="col-sm-12">
                                    <h4>Store as unit test</h4>
                                </div>
                            </div>
                            <div class="row row-spacing">
                                <div class="col-sm-1">
                                    <label>Name</label>
                                </div>
                                <div class="col-sm-6">
                                    <input [formControl]='testNameControl' class='form-control'>
                                </div>
                                <div class="col-sm-3">
                                    <label>
                                        <input type="checkbox" [formControl]='isTestCaseCtrl'>
                                        Store as test case
                                    </label>
                                </div>
                                <div class="col-sm-2">
                                    <button (click)="storeSession($event)" [disabled]="!sessionName"
                                        class="btn btn-default btn-block">Store</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="panel panel-default update-panel" *ngIf="exerciseReady && exercise.state == 'APPROVED'">
                <div class="panel-heading">
                    <h3 class="panel-title">Update exercise</h3>
                </div>
                <div class="panel-body">
                    <div *ngIf="!isDefinitionValid" class="row row-spacing">
                        <div class="col-xs-12">
                            <div class="alert alert-warning">
                                The exercise contains errors. It might not work correctly.
                            </div>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Commit message</label>
                        </div>
                        <div class="col-sm-10">
                            <textarea formControlName='commitMessage' class='form-control'
                                [(ngModel)]="exercise.commitMessage" required
                                [ngClass]="{'input-invalid': commitMessageControl.errors}"></textarea>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-sm-2">
                            <label>Type of update</label>
                        </div>
                        <div class="col-sm-7">
                            <select formControlName='updateType' class='form-control' [(ngModel)]="exercise.updateType">
                                <option value="MINOR" [selected]="true">Minor</option>
                                <option value="MAJOR">Major</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <!-- !isDefinitionValid -->
                            <button class="btn btn-md btn-primary btn-block" type="button" [attr.title]="saveTitle"
                                (click)="commit()"
                                [disabled]="(changeCounter==0 && canDeactivate()) || !form.valid || !metadataValid">
                                <i class="fa fa-save"></i>
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <publish-confirm *ngIf="confirmPublish" class="panel-wrapper" (confirm)="doPublish()"
            (cancel)="cancelPublish()">
        </publish-confirm>

        <div class="panel-wrapper">
            <div class="row row-spacing" *ngIf="exerciseReady">
                <div *ngIf="changeCounter==0 && canDeactivate(); else noDeactivate">
                    <!-- no changes-->
                    <div class="col-xs-3" *ngIf="approveButtonVisible()">
                        <button class="btn btn-md btn-info btn-block" type="button" (click)="approve()"
                            title="Approve this exercise">
                            <i class="fa fa-check"></i>
                            <span>Approve exercise</span>
                        </button>
                    </div>
                    <div class="col-xs-3" *ngIf="exercise.state == 'DRAFT' && !approveButtonVisible()">
                        <button class="btn btn-md btn-primary btn-block" type="button" [attr.title]="saveTitle"
                            (click)="commit()" [disabled]="true">
                            <i class="fa fa-save"></i>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
                <ng-template #noDeactivate>
                    <div class="col-xs-3" *ngIf="exercise.state == 'DRAFT'">
                        <button class="btn btn-md btn-primary btn-block" type="button" [attr.title]="saveTitle"
                            (click)="commit()" [disabled]="false">
                            <i class="fa fa-save"></i>
                            <span>Save</span>
                        </button>
                    </div>
                </ng-template>
                <div class="col-xs-3" *ngIf="exercise.state == 'APPROVED'">
                    <button class="btn btn-md btn-block" [ngClass]="exercise.tested ? 'btn-success' : 'btn-info'"
                        [disabled]="exercise.tested || changed" type="button"
                        [title]="!exercise.tested ? 'Mark this exercise as tested' : 'Current version is tested'"
                        (click)="setTested()">
                        <i class="fa fa-check"></i>
                        <span *ngIf="!exercise.tested">Confirm tested</span>
                        <span *ngIf="exercise.tested">Current version is tested</span>
                    </button>
                </div>
                <div class="col-xs-3">
                    <button class="btn btn-md btn-default btn-block" type="button" (click)="run()"
                        [disabled]="createSessionPending" [attr.title]="runTitle">
                        <i class="fa fa-eye"></i>
                        <span>Run</span>
                    </button>
                </div>
                <div class="col-xs-3">
                    <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()"
                        title="Discard changes and close the editor">
                        <i class="fa fa-ban"></i>
                        <span>Close</span>
                    </button>
                </div>
                <div class="col-xs-3" *ngIf="exercise.state == 'APPROVED' && lastPublishedRetrieved && canPublish()">
                    <button class="btn btn-md btn-warning btn-block" type="button" (click)="publish()"
                        [title]="getPublishButtonTitle()" [disabled]="this.changed || compareVersionNumbers() < 1">
                        <i class="fa fa-leanpub"></i>
                        <span>Publish</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="modal fade interpretations-modal" #interpretationsModal>
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title">Interpretation of symbols</h4>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="interpretations" class="row">
                            <div class="col-xs-12">
                                <symbol-editor-wrapper [symbols]="interpretations" #interpretationEditor
                                    (symbolsChanged)="handleSymbolsChanged($event)">
                                </symbol-editor-wrapper>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            (click)="confirmInterpretation()">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #unsupportedReferences>
        <div class="panel-wrapper">
            <div class="row">
                <div class="col-xs-12">
                    This exercise contains references to interactions from other exercises. Support for this feature has
                    been removed but will be added again in the feature. You can still run the exercise from the
                    folder page. If you have any questions, please contact Algebrakit support.
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-xs-3">
                    <button class="btn btn-md btn-default btn-block" type="button" (click)="cancel()"
                        title="Close the editor">
                        <i class="fa fa-ban"></i>
                        <span>Close</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>