import {
    Component, Input, Output, ViewChild, AfterViewInit, ElementRef, OnDestroy, EventEmitter, NgZone, OnInit, SimpleChanges} from '@angular/core';
import { ExerciseRef } from '../../../authoring/types/cms-metadata.types'
import { ISessionId, IErrorData } from '../../types/algebrakit.type';
import { IAlgebraKIT, IDebugInformation } from '../../types/algebrakit.type';
import { SessionService } from '../../services/session.service';
import 'rxjs/Rx';
import { IExerciseSpec } from '../../types/metadata.type';
import { IValidationReport } from '../../../authoring/types/ValidationReport';

declare let AlgebraKIT: IAlgebraKIT;

// Angular wrapper for akit-exercise.
// parameters:
// - app-id
// - session-id (optional)
// - exercise-spec (optional), if given, a new session will be created.
// Either exercise-spec or session-id must be given

@Component({
    selector: 'cms-interaction',
    templateUrl: './cms-interaction.component.html',
    styleUrls: ['./cms-interaction.component.css']
})
export class CmsInteractionComponent implements AfterViewInit, OnDestroy {
    currentSessionId: string;
    public debug: IDebugInformation;
    showDebug = false;

    @Input() html: string;
    @Input() appId: string;
    @Input() testSessionId: string;
    @Input() exerciseSpec: IExerciseSpec;
    @Input() exerciseRef: ExerciseRef;
    @Input() scoringModel: string;
    @Input() cycle: number;
    @Input() showErrors: boolean = false;

    @Input() contentVersion: string = "0.0";

    // publish session-id when session is created
    @Output() newSessionId: EventEmitter<ISessionId> = new EventEmitter<ISessionId>();
    @Output() debugInfo: EventEmitter<IDebugInformation> = new EventEmitter<IDebugInformation>();
    @Output() error: EventEmitter<IErrorData> = new EventEmitter<IErrorData>();
    @Output() validationReport: EventEmitter<IValidationReport> = new EventEmitter<IValidationReport>();

    @ViewChild('interactionWrapper', {static: false}) root: ElementRef;

    errorMessage: string;

    mustReGenerate: boolean;

    constructor(
        private sessionService: SessionService    ) { }

    public generate() {
        this.mustReGenerate = false;
        if (this.html) {
            this.createFromHTML(this.html);
        } else if (this.exerciseSpec) {
            this.createFromSpec(this.exerciseSpec);
        } else if (this.exerciseRef) {
            this.createFromRef(this.exerciseRef);
        } else if (this.testSessionId) {
            this.createFromTestSession(this.testSessionId);
        }
    }

    public clearDebug() {
        this.showDebug = false;
        this.debug = null;
    }

    clearCurrent() {
        if (this.currentSessionId) {
            if (this.root) this.root.nativeElement.innerHTML = '';
            this.currentSessionId = null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.mustReGenerate = true;
    }

    ngAfterViewInit(): void {
        this.generate();
    }

    ngAfterViewChecked(): void {
        if (this.mustReGenerate) {
            this.generate();
        }
    }

    createFromHTML(html: string) {
        this.root.nativeElement.innerHTML = html;

        AlgebraKIT.addExerciseListener((event: any) => {
            if (event && event.event === 'exercise-created' && event.sessionId && !this.currentSessionId) {
                this.currentSessionId = event.sessionId;
                this.newSessionId.emit({
                    appId: this.appId,
                    sessionId: event.sessionId
                });
            }
        });
    }

    public command(name: string, args: any) {
        if (!this.currentSessionId) return;
        return AlgebraKIT.exerciseCommand(this.currentSessionId, name, args);
    }

    createFromSpec(exerciseSpec: IExerciseSpec) {
        this.errorMessage = null;
        this.clearCurrent();
        this.sessionService.createSession(exerciseSpec, { 'start-active': true, 'show-progress-and-score': !!this.scoringModel }, false, this.scoringModel, this.contentVersion).toPromise()
            .then(sessionData => {

                if (sessionData.validationReport) {
                    this.validationReport.emit(sessionData.validationReport);
                }

                //get size in kb of sessionData
                let size = JSON.stringify(sessionData).length / 1024;
                
                
                if (size > 200) {
                    this.error.emit({
                        msg: "This exercise is too big, please author it in a different way or create multiple exercises.",
                        data: null
                    })
                    return

                }

                if (sessionData.success) {
                    this.newSessionId.emit({
                        appId: this.appId,
                        sessionId: sessionData.sessionId
                    });
                    if (sessionData.debugInformation) {
                        this.debug = sessionData.debugInformation;
                        this.debugInfo.emit(sessionData.debugInformation);
                    }
                    if (sessionData.html) {
                        this.createFromHTML(sessionData.html);
                    }
                } else {
                    let msg = sessionData.errorMessage || 'An error occurred';
                    this.error.emit({
                        msg: msg,
                        data: sessionData.errorData
                    })
                    if (this.showErrors) {
                        this.errorMessage = msg;
                    }
                }
            }).catch((err) => this.handleError(err));
    }

    createFromRef(exerciseRef: ExerciseRef) {
        this.clearCurrent();
        this.sessionService.createSessionFromRef(exerciseRef, { 'show-progress-and-score': !!this.scoringModel }).toPromise()
            .then(sessionData => {
                this.newSessionId.emit({
                    appId: this.appId,
                    sessionId: sessionData.sessionId
                });
                if (sessionData.html) {
                    this.createFromHTML(sessionData.html);
                }
            }).catch((err) => this.handleError(err));
    }

    createFromTestSession(sessionId: string) {
        this.sessionService.compareSession(sessionId).toPromise()
            .then(sessionData => {
                this.newSessionId.emit({
                    appId: this.appId,
                    sessionId: sessionData.sessionId
                });
                if (sessionData.html) {
                    this.createFromHTML(sessionData.html);
                }
            }).catch((err) => this.handleError(err));
    }

    handleError(err: any) {
        if (err && err.originalError && err.originalError.additionalInfo && err.originalError.additionalInfo.validationReport) {
            this.validationReport.emit(JSON.parse(err.originalError.additionalInfo.validationReport));
            delete err.originalError.additionalInfo.validationReport;
        }

        this.error.emit({
            msg: err.message,
            data: err.originalError && err.originalError.additionalInfo ? err.originalError.additionalInfo : null
        })
    }

    ngOnDestroy() {
        if (this.currentSessionId) {
            AlgebraKIT.removeWidgetBySessionId(this.currentSessionId, false);
        }
    }
}
