
import { map } from 'rxjs/operators';
import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import { CmsCourse, CmsSubject, CmsCourseAudience, GeneralUpdateRequest } from '../../types/navigator.types';
import { COUNTRIES, QUESTION_MODES, MetadataService } from '../../../algebrakit/services/metadata.service';
import { CourseService } from '../../services/course.service';
import { SubjectService } from '../../services/subject.service';
import { UserService } from '../../../security/services/user.service';
import { NameIdPair } from '../../types/cms-metadata.types';
import { IAlgebraKIT } from '../../../algebrakit/types/algebrakit.type';
import { EditorAssetResolveResult } from '../../../algebrakit/types/metadata.type';

declare let $: any;
declare let AlgebraKIT: IAlgebraKIT;

@Component({
    templateUrl: './course-editor.component.html',
    styleUrls: ['./course-editor.component.less', '../../../../assets/shared.less']
})
export class CourseEditorComponent implements OnInit {
    countries = COUNTRIES;
    palettes: NameIdPair[] = AlgebraKIT._api.getDefaultEditors();
    questionModes = QUESTION_MODES;
    editorAssetResolveList: EditorAssetResolveResult[];
    editorAssetResolveListChanged: boolean = false;

    form: FormGroup;
    course: CmsCourse;
    rootSubject: CmsSubject;
    nameCtrl = new FormControl('', Validators.required);
    namespaceCtrl = new FormControl('', Validators.required);
    defaultEditorCtrl = new FormControl('');
    applyEditorToChildsCtrl = new FormControl('');
    defaultQuestionModeCtrl = new FormControl('');
    defaultAudienceCtrl = new FormControl('');
    applyQuestionModeToChildsCtrl = new FormControl('');
    applyAudienceToChildsCtrl = new FormControl('');

    overwriteEditorInDraftChildsCtrl = new FormControl('');
    overwriteQuestionModeInDraftChildsCtrl = new FormControl('');
    overwriteAudienceInDraftChildsCtrl = new FormControl('');
    overwriteEditorInApprovedChildsCtrl = new FormControl('');
    overwriteQuestionModeInApprovedChildsCtrl = new FormControl('');
    overwriteAudienceInApprovedChildsCtrl = new FormControl('');

    showFreeAccountNamespacesCtrl = new FormControl('');

    preselectRegionCtrl = new FormControl('');
    scoringModelCtrl = new FormControl('');
    themeCtrl = new FormControl('');
    blacklistCtrl = new FormControl('');

    duplicateFeResolveNames: number[] = [];
    duplicateAudienceNames: number[] = [];
    duplicateAudienceBase: number[] = [];

    namespaces: NameIdPair[] = [];

    busy: boolean;

    onCloseUrl: string;

    get applyEditorToChilds(): boolean {
        return this.applyEditorToChildsCtrl.value;
    }
    get applyQuestionModeToChilds(): boolean {
        return this.applyQuestionModeToChildsCtrl.value;
    }
    get applyAudienceToChilds(): boolean {
        return this.applyAudienceToChildsCtrl.value;
    }

    get customPalettes(): string[] {
        return this.editorAssetResolveList
            ? this.editorAssetResolveList.map(editor => editor.formulaEditorId)
            : [];
    }

    get allPalettes() {
        let customPalettes = this.customPalettes
            ? this.customPalettes
                .filter(id => !this.palettes.find(editor => editor.id == id))
                .map(id => {
                    return {
                        id: id,
                        name: id,
                    }
                })
            : [];
        return this.palettes.concat(customPalettes);
    }

    constructor(
        private courseService: CourseService,
        private subjectService: SubjectService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private userService: UserService,
        private metaDataService: MetadataService,
    ) {
        this.form = this.formBuilder.group({
            namespace: this.namespaceCtrl,
            name: this.nameCtrl,
            defaultEditor: this.defaultEditorCtrl,
            applyEditorToChilds: this.applyEditorToChildsCtrl,
            defaultQuestionMode: this.defaultQuestionModeCtrl,
            defaultAudience: this.defaultAudienceCtrl,

            applyQuestionModeToChilds: this.applyQuestionModeToChildsCtrl,
            applyAudienceToChilds: this.applyAudienceToChildsCtrl,

            overwriteEditorInDraftChilds: this.overwriteEditorInDraftChildsCtrl,
            overwriteQuestionModeInDraftChilds: this.overwriteQuestionModeInDraftChildsCtrl,
            overwriteAudienceInDraftChilds: this.overwriteAudienceInDraftChildsCtrl,
            overwriteEditorInApprovedChilds: this.overwriteEditorInApprovedChildsCtrl,
            overwriteQuestionModeInApprovedChilds: this.overwriteQuestionModeInApprovedChildsCtrl,
            overwriteAudienceInApprovedChilds: this.overwriteAudienceInApprovedChildsCtrl,

            showFreeAccountNamespaces: this.showFreeAccountNamespacesCtrl,

            preselectRegion: this.preselectRegionCtrl,
            scoringModel: this.scoringModelCtrl,
            theme: this.themeCtrl,
            blacklist: this.blacklistCtrl
        })
    }

    canManageCourse() {
        return !this.course.namespace || this.course.namespace.length === 0 || this.userService.canManageCourse(this.course);
    }

    canManageFreeCourse() {
        return !this.canManageCourse() && this.course.free;
    }

    cancel() {
        if (this.onCloseUrl == '_BACK') {
            this.location.back();
        }
        else if (this.onCloseUrl) {
            this.router.navigate([this.onCloseUrl]);
        }
        else {
            this.router.navigate(['/courses']);
        }
    }

    get overwriteNotifications() {
        let msgArr: string[] = [];
        let recursivelyText = "<strong>recursively in all subfolders</strong>";
        if (this.overwriteEditorInDraftChildsCtrl.value == true) {
            msgArr.push(`Overwrite formula editors in all DRAFT child exercises ${this.applyEditorToChildsCtrl.value ? recursivelyText : ""}`);
        }
        if (this.overwriteEditorInApprovedChildsCtrl.value == true) {
            msgArr.push(`Overwrite formula editors in all APPROVED child exercises ${this.applyEditorToChildsCtrl.value ? recursivelyText : ""}`);
        }
        if (this.overwriteQuestionModeInDraftChildsCtrl.value == true) {
            msgArr.push(`Overwrite question modes in all DRAFT child exercises ${this.applyQuestionModeToChildsCtrl.value ? recursivelyText : ""}`);
        }
        if (this.overwriteQuestionModeInApprovedChildsCtrl.value == true) {
            msgArr.push(`Overwrite question modes in all APPROVED child exercises ${this.applyQuestionModeToChildsCtrl.value ? recursivelyText : ""}`);
        }
        if (this.overwriteAudienceInDraftChildsCtrl.value == true) {
            msgArr.push(`Overwrite audiences in all DRAFT child exercises ${this.applyAudienceToChildsCtrl.value ? recursivelyText : ""}`);
        }
        if (this.overwriteAudienceInApprovedChildsCtrl.value == true) {
            msgArr.push(`Overwrite audiences in all APPROVED child exercises ${this.applyAudienceToChildsCtrl.value ? recursivelyText : ""}`);
        }
        return msgArr;
    }

    onSubmit() {
        if (this.overwriteNotifications.length > 0) {
            //show confirmation popup first
            var modal = $('.confirmation-modal').first();
            modal.modal('show');
        }
        else {
            this.confirmSubmit();
        }
    }

    confirmSubmit() {
        //set the sort order
        for (let i = 0; i < this.course.audiences.length; i++) {
            this.course.audiences[i].sortOrder = i;
        }

        this.course = {
            ...this.form.value,
            id: this.course.id,
            audiences: this.course.audiences,
            free: this.course.free,
            options: {
                scoringModel: this.scoringModelCtrl.value,
                theme: this.themeCtrl.value,
            }
        };

        if (this.isAdmin()) {
            this.course.options.interactionBlacklist = this.blacklistCtrl.value && this.blacklistCtrl.value.trim().length > 0
                ? this.blacklistCtrl.value
                    .split(',')
                    .map((val: string) => val.trim())
                : null;
        }

        let updateReq = new GeneralUpdateRequest();
        updateReq.defaultEditor = this.defaultEditorCtrl.value;
        updateReq.defaultQuestionMode = this.defaultQuestionModeCtrl.value;
        updateReq.defaultAudience = this.defaultAudienceCtrl.value;
        updateReq.applyEditorToChilds = this.applyEditorToChildsCtrl.value;
        updateReq.applyQuestionModeToChilds = this.applyQuestionModeToChildsCtrl.value;
        updateReq.applyAudienceToChilds = this.applyAudienceToChildsCtrl.value;

        updateReq.overwriteEditorInDraftChilds = this.overwriteEditorInDraftChildsCtrl.value;
        updateReq.overwriteEditorInApprovedChilds = this.overwriteEditorInApprovedChildsCtrl.value;
        updateReq.overwriteQuestionModeInDraftChilds = this.overwriteQuestionModeInDraftChildsCtrl.value;
        updateReq.overwriteQuestionModeInApprovedChilds = this.overwriteQuestionModeInApprovedChildsCtrl.value;
        updateReq.overwriteAudienceInDraftChilds = this.overwriteAudienceInDraftChildsCtrl.value;
        updateReq.overwriteAudienceInApprovedChilds = this.overwriteAudienceInApprovedChildsCtrl.value;

        if (this.editorAssetResolveListChanged) {
            updateReq.editorAssetResolveList = this.editorAssetResolveList;
        }

        this.busy = true;

        this.courseService.storeCourse(this.course, updateReq)
            .then(() => {
                this.busy = false;
                this.editorAssetResolveListChanged = false;
                var modal = $('.confirmation-modal').first();
                modal.modal('hide');
                this.toastr.success(`Course '${this.course.name}' was succesfully saved`, "Success");
                this.router.navigate(['/courses']);
            })
            .catch(err => {
                console.log(err);
                var modal = $('.confirmation-modal').first();
                modal.modal('hide');
                let msg = err.message || "An error occured while saving the course";
                this.toastr.error(msg, "Error");
            });
    }

    addFeResolveEntry(index?: number) {
        let newEntry: EditorAssetResolveResult = {
            courseId: this.course.id,
            formulaEditorId: null,
            assetId: null
        }

        if (!this.editorAssetResolveList) {
            this.editorAssetResolveList = [];
        }
        if (index == null) {
            this.editorAssetResolveList.push(newEntry);
        }
        else {
            this.editorAssetResolveList.splice(index + 1, 0, newEntry);
        }
        this.editorAssetResolveListChanged = true;
    }

    removeFeResolveEntry(index: number) {
        this.editorAssetResolveList.splice(index, 1);
        this.editorAssetResolveListChanged = true;
    }

    moveFeResolveEntryUp(index: number) {
        if (index > 0) {
            let q = this.editorAssetResolveList.splice(index, 1);
            this.editorAssetResolveList.splice(index - 1, 0, q[0]);
            this.editorAssetResolveListChanged = true;
        }
    }

    moveFeResolveEntryDown(index: number) {
        if (index < this.editorAssetResolveList.length - 1) {
            let q = this.editorAssetResolveList.splice(index, 1);
            this.editorAssetResolveList.splice(index + 1, 0, q[0]);
            this.editorAssetResolveListChanged = true;
        }
    }

    feResolveEntryChanged() {
        this.editorAssetResolveListChanged = true;
    }

    getDuplicateFeResolveProps(index: number) {
        let result: string[] = [];
        if (this.duplicateFeResolveNames.indexOf(index) !== -1) {
            result.push('formulaEditorId');
        }
        return result;
    }

    addAudience(index?: number) {
        let newAudience: CmsCourseAudience = {
            name: '',
            courseId: this.course.id,
            baseAudienceId: null
        }

        if (!this.course.audiences) {
            this.course.audiences = [];
        }
        if (index == null) {
            this.course.audiences.push(newAudience);
            return;
        }
        this.course.audiences.splice(index + 1, 0, newAudience);
    }

    removeAudience(index: number) {
        this.course.audiences.splice(index, 1);
    }

    moveAudienceUp(index: number) {
        if (index > 0) {
            let q = this.course.audiences.splice(index, 1);
            this.course.audiences.splice(index - 1, 0, q[0]);
        }
    }

    moveAudienceDown(index: number) {
        if (index < this.course.audiences.length - 1) {
            let q = this.course.audiences.splice(index, 1);
            this.course.audiences.splice(index + 1, 0, q[0]);
        }
    }

    addPreselectedAudiences() {
        let region = this.preselectRegionCtrl.value;
        this.metaDataService.getPreselectedAudiences(region).then(audiences => {
            if (this.canManageFreeCourse()) {
                this.course.audiences = [];
            }
            else if (audiences.length > 0 && this.course.audiences.length === 1) {
                let audience = this.course.audiences[0];
                if (audience.name == null && audience.baseAudienceId == null) {
                    this.course.audiences = [];
                }
            }
            for (let audience of audiences) {
                let newCourseAudience: CmsCourseAudience = {
                    courseId: this.course.id,
                    name: audience.name,
                    baseAudienceId: audience.audienceID
                }
                this.course.audiences.push(newCourseAudience);
            }
            if (this.canManageFreeCourse()) {
                this.defaultAudienceCtrl.setValue(this.course.audiences[0].baseAudienceId);
            }
        });
    }

    hasDuplicateEditorAssetResolves() {
        //Duplicate names
        this.duplicateFeResolveNames = [];
        this.editorAssetResolveList
            .filter(a => a.formulaEditorId != null && a.formulaEditorId != '')
            .forEach(a1 => {
                let a2 = this.editorAssetResolveList
                    .filter(a2 => a1 !== a2)
                    .filter(a => a.formulaEditorId != null && a.formulaEditorId != '')
                    .find(a2 => a1.formulaEditorId.trim() === a2.formulaEditorId.trim())
                if (a2) {
                    this.duplicateFeResolveNames.push(this.editorAssetResolveList.indexOf(a1));
                    this.duplicateFeResolveNames.push(this.editorAssetResolveList.indexOf(a2));
                }
            });

        return this.duplicateFeResolveNames.length > 0
    }

    hasDuplicateAudienceProps() {
        //Duplicate names
        this.duplicateAudienceNames = [];
        this.course.audiences
            .filter(a => a.name != null && a.name != '')
            .forEach(a1 => {
                let a2 = this.course.audiences
                    .filter(a2 => a1 !== a2)
                    .filter(a => a.name != null && a.name != '')
                    .find(a2 => a1.name.trim() === a2.name.trim())
                if (a2) {
                    this.duplicateAudienceNames.push(this.course.audiences.indexOf(a1));
                    this.duplicateAudienceNames.push(this.course.audiences.indexOf(a2));
                }
            });

        //Duplicate baseAudience
        this.duplicateAudienceBase = [];
        this.course.audiences
            .filter(a => a.baseAudienceId != null && a.baseAudienceId != '')
            .forEach(a1 => {
                let a2 = this.course.audiences
                    .filter(a2 => a1 !== a2)
                    .filter(a => a.baseAudienceId != null && a.baseAudienceId != '')
                    .find(a2 => a1.baseAudienceId === a2.baseAudienceId)
                if (a2) {
                    this.duplicateAudienceBase.push(this.course.audiences.indexOf(a1));
                    this.duplicateAudienceBase.push(this.course.audiences.indexOf(a2));
                }
            });

        return this.duplicateAudienceNames.length > 0 || this.duplicateAudienceBase.length > 0;
    }

    audienceValid() {
        return this.course.audiences
            && this.course.audiences.length > 0
            && !this.hasDuplicateAudienceProps()
            && this.course.audiences.every(a => a.name && a.name != '' && a.baseAudienceId && a.baseAudienceId != '');
    }

    feAssetResolveListValid() {
        return this.editorAssetResolveList == null
            || this.editorAssetResolveList.length == 0
            || (
                !this.hasDuplicateEditorAssetResolves()
                && this.editorAssetResolveList.every(entry =>
                    entry.formulaEditorId && entry.formulaEditorId != ''
                    && entry.assetId && entry.assetId != ''
                )
            );
    }

    getDuplicateProps(index: number) {
        let result: string[] = [];
        if (this.duplicateAudienceBase.indexOf(index) !== -1) {
            result.push('baseAudienceId');
        }
        if (this.duplicateAudienceNames.indexOf(index) !== -1) {
            result.push('name');
        }
        return result;
    }

    getNamespaceName(namespaceId: string) {
        let ns = this.namespaces.find(ns => ns.id == namespaceId)
        return ns != null
            ? ns.name
            : namespaceId;
    }

    isAdmin() {
        return this.userService.canEditCoursesGlobal();
    }

    ngSelectNamespaceChanged(value: any) {
        if (!value) {
            return;
        }
        let id = value.id;
        if (id != null) {
            return; //Value from existing namespace list was used, no action needed
        }
        value.id = value.name;
        this.namespaceCtrl.setValue(value.id);
    }

    initNamespaces() {
        let nsPromise: Promise<NameIdPair[]>;

        if (this.namespaces && this.namespaces.length > 0 && this.course.namespace && this.course.namespace.length > 0) {
            //If the namespace list already exists and the course already has a namespace assigned, no need to retrieve it again
            return;
        }

        if (!this.course.namespace || this.course.namespace.length === 0) {
            nsPromise = this.courseService.getManageableNamespaces(this.showFreeAccountNamespacesCtrl.value)
                .toPromise();
        }
        else {
            nsPromise = this.courseService.getCourseNamespace(this.course.id).pipe(
                map(result => [result]))
                .toPromise();
        }

        nsPromise.then(namespaces => {
            this.namespaces = namespaces
            if (this.course.namespace && this.course.namespace.length > 0) {
                this.namespaceCtrl.setValue(this.course.namespace);
            }
        });
    }

    ngOnInit() {
        this.route.queryParams
            .subscribe((params: Params) => {
                this.onCloseUrl = params['onCloseUrl'];
            });
        this.route.data
            .subscribe((data: { course: CmsCourse }) => {
                let course = data.course;

                //Check permission
                let user = this.userService.getUser();
                if (!user.canEditCourse(course) && !user.canManageCourse(course)) {
                    this.toastr.error("Unauthorized to edit this course", "Error");
                    this.router.navigate(['/courses']);
                }

                this.course = course;
                this.form.patchValue({
                    name: course.name,
                });

                if (!this.course.audiences) {
                    this.course.audiences = [];
                }

                if (this.course.audiences.length === 0) {
                    this.course.audiences.push({
                        courseId: this.course.id,
                        name: null,
                        baseAudienceId: null,
                    })
                }

                if (!this.course.options) {
                    this.course.options = {}
                }

                //Patch CourseOptions
                this.form.patchValue({
                    scoringModel: this.course.options.scoringModel,
                    theme: this.course.options.theme
                })

                //interaction blacklist (only for admins)
                if (this.isAdmin()) {
                    let blacklistArr: string[] = this.course.options.interactionBlacklist;
                    if (blacklistArr && blacklistArr.length > 0) {
                        this.form.patchValue({
                            blacklist: blacklistArr.join(',')
                        })
                    }
                }

                this.initNamespaces();

                //Update namespaces whenever the "free namespaces" checkbox value changes
                this.showFreeAccountNamespacesCtrl.valueChanges.subscribe(() => {
                    this.initNamespaces();
                })

                if (this.canManageFreeCourse() && course.audiences && course.audiences.length > 0) {
                    let audienceId = course.audiences[0].baseAudienceId;
                    let audienceSpec = this.metaDataService.getAudience(audienceId);
                    let country = this.countries.find(c => c.name == audienceSpec.country);
                    this.preselectRegionCtrl.setValue(country.id);
                }

                if (this.canManageCourse() && this.course.audiences && this.course.audiences.length > 0) {
                    this.metaDataService.getAudienceResolveList(course.id).then(resolveList => {
                        resolveList.forEach(r => {
                            let audience = course.audiences.find(a => a.baseAudienceId == r.baseAudienceId);
                            if (audience == null) {
                                return;
                            }
                            let i = this.course.audiences.indexOf(audience);
                            this.course.audiences[i].resolvedAudience = r.audience;
                            this.course.audiences[i].editor = r.editor;
                        })
                    })
                    this.metaDataService.getEditorAssetResolveList(course.id).then(resolveList => {
                        this.editorAssetResolveList = resolveList;
                        this.editorAssetResolveListChanged = false;
                    })
                }

                if (course.rootSubject) {
                    return this.subjectService.getSubject(course.rootSubject.id)
                        .toPromise()
                        .then(subject => {
                            this.rootSubject = subject;
                            this.form.patchValue({ defaultEditor: subject.defaultEditor })
                            this.form.patchValue({ defaultQuestionMode: subject.defaultQuestionMode })
                            this.form.patchValue({ defaultAudience: subject.defaultAudience })
                        })
                }
                this.rootSubject = null;
                this.form.patchValue({
                    defaultEditor: "equations",
                    defaultQuestionMode: "ONE_BY_ONE"
                });
            });
    }
}

