
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsCourse, GeneralUpdateRequest, NameIdPair, UpdateRequest} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';

const URL_BASE_COURSE_INSTANCE = "api/course/instance";
const URL_BASE_COURSES = "api/course/all";
const URL_BASE_COURSE = "api/course";

@Injectable()
export class CourseService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService
    ) {
        super(http, toastr, concurrencyService);
    }

    getCourse(id: string): Observable<CmsCourse> {
        return this.http.get(URL_BASE_COURSE_INSTANCE + '/' + id).pipe(
            map((obj: any) => this._getCourseFromJson(obj)),
            catchError(error => this._serverError(error)),);
    }

    //retrieve all courses for the current user
    getCoursesList(namespaceFilter?: string[], excludeFree?: boolean, page?: number, pageSize?: number, searchFilter?: string): Observable<CmsCourse[]> {
        let response: Observable<CmsCourse[]>;

        if (namespaceFilter && namespaceFilter.length > 0) {
            response = this.http.post<CmsCourse[]>(URL_BASE_COURSES + '/for-namespaces', {
                namespaces: namespaceFilter,
                excludeFree: !!excludeFree,
                page: page,
                pageSize: pageSize,
                searchFilter: searchFilter
            })
        }
        else {
            let url = URL_BASE_COURSES;
            if (excludeFree) {
                url += "/exclude-free";
            }
            if (page != null && pageSize != null) {
                url += `/${page}/${pageSize}`
            }
            if (searchFilter != null && searchFilter.length > 0) {
                url += `/${searchFilter}`
            }
            response = this.http.get<CmsCourse[]>(url);
        }
        return response.pipe(
            map((arr: CmsCourse[]) =>
                arr.sort((a, b) =>
                    a.name === b.name ? 0 : a.name < b.name ? -1 : 1
                )
            ),
            catchError(error => this._serverError(error)),);
    }

    //retrieve all course name->id pairs for the current user
    getCourseNameList(): Observable<NameIdPair[]> {
        return this.http.get(URL_BASE_COURSE + '/names/all').pipe(
            map((arr: NameIdPair[]) =>
                arr.sort((a, b) =>
                    a.name === b.name ? 0 : a.name < b.name ? -1 : 1
                )
            ),
            catchError(error => this._serverError(error)),);
    }

    //retrieve the namespaces for all existing courses (can only be used by a user with global.course.manage permissions)
    getCourseNamespaces(includingFree?: boolean): Observable<string[]> {
        return this.http.get<string[]>(URL_BASE_COURSE + '/global/namespaces' + (includingFree ? "/including-free" : "")).pipe(
            catchError(error => this._serverError(error)));
    }

    getCourseNamespace(courseId: string): Observable<NameIdPair> {
        return this.http.get<NameIdPair>(`${URL_BASE_COURSE}/get-namespace/${courseId}`).pipe(
            catchError(error => this._serverError(error)));
    }

    getManageableNamespaces(includingFree?: boolean): Observable<NameIdPair[]> {
        return this.http.get<NameIdPair[]>(URL_BASE_COURSE + '/get-manageable-namespaces'+ (includingFree ? "/including-free" : "")).pipe(
            catchError(error => this._serverError(error)));
    }

    storeCourse(course: CmsCourse, params?: GeneralUpdateRequest): Promise<string | any> {
        let updateRequest = new UpdateRequest(course, params);
        return this.http.post(URL_BASE_COURSE, updateRequest, {responseType: 'text'})
            .toPromise()
            .catch(this._serverError);
    }

    deleteCourse(id: string): Promise<boolean | any[]> {
        return this.http.delete(URL_BASE_COURSE_INSTANCE + '/' + id).pipe(
            map(() => true))
            .toPromise()
            .catch(this._serverError);
    }

    private _getCourseFromJson(obj: any): CmsCourse {
        //        let studentLevels:StudentLevel[];
        //        if(obj.studentLevels) {
        //            studentLevels = obj.studentLevels.map( (item:any) => ({
        //                audience: item.akitAudience,
        //                description: item.description,
        //                name: item.name
        //            }))
        //        }

        let result: CmsCourse = {
            id: obj.id,
            name: obj.name,
            audiences: obj.audiences,
            rootSubject: obj.rootSubject,
            trashSubject: obj.trashSubject,
            namespace: obj.namespace,
            free: obj.free,
            options: obj.options
        }
        return result;
    }

    createCourse(name: string): CmsCourse {
        return {
            name: name,
            id: null,
            audiences: [],
            namespace: ""
        }
    }

}