import {
    Component, Input, Output, ViewChild, ElementRef,
    OnDestroy, EventEmitter, NgZone
} from '@angular/core';
import { IAlgebraKIT, ISolutionData, IErrorData } from '../../types/algebrakit.type';
import { SessionService } from '../../services/session.service';
import 'rxjs/Rx';
import { ToastrService } from 'ngx-toastr';

declare let AlgebraKIT: IAlgebraKIT;

// Angular wrapper for various widgets types (show solution only)
// parameters:
// - app-id
// - session-id

@Component({
    selector: 'cms-solution',
    template: '<div #root></div>',
    //styleUrls: ['./cms-interaction.component.css']
})
export class CmsSolutionComponent implements OnDestroy {

    @Input() appId: string;
    @Input() sessionId: string;

    @Output() error: EventEmitter<IErrorData> = new EventEmitter<IErrorData>();

    @ViewChild('root', {static: false}) root: ElementRef;

    constructor(
        private sessionService: SessionService,
        private _ngZone: NgZone,
        private toastr: ToastrService
    ) { }

    generate() {
        if (this.sessionId) {
            AlgebraKIT.removeWidgetBySessionId(this.sessionId, false);
            this.sessionService.getSolution(this.sessionId, this.appId).toPromise()
                .then((solutionData: ISolutionData) => {
                    if (solutionData.html) {
                         this.root.nativeElement.innerHTML = solutionData.html;
                    } else if(!solutionData.success && solutionData.errorData) {
                        let msg = solutionData.msg || 'An error occurred';
                        this.error.emit({
                            msg: msg,
                            data: solutionData.errorData
                        })
                    }
                });

        }
    }

    ngOnChanges() {
        this.generate();
    }

    public command(name: string, args: any) {
        if (!this.sessionId) return;
        return AlgebraKIT.exerciseCommand(this.sessionId, name, args);
    }

    ngOnDestroy() {
        if (this.sessionId) {
            AlgebraKIT.removeWidgetBySessionId(this.sessionId, false);
        }
    }
}