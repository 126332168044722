
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { SortedCheckResults, CheckedResource, partitionBySeverity, Anomaly } from '../types/verify.types';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';

const URL_BASE_VERIFY = "api/verify";

@Injectable()
export class VerifyService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService
    ) {
        super(http, toastr, concurrencyService);
    }

    verifyCourseForPublish(courseId: string): Observable<SortedCheckResults[]> {
        return this.http.get<CheckedResource[]>(`${URL_BASE_VERIFY}/publish/${courseId}`).pipe(
            map(items => partitionBySeverity(items)),
            catchError(error => this._serverError(error)),);
    }

    verifySubjectForPublish(subjectId: string): Observable<SortedCheckResults[]> {
        return this.http.get<CheckedResource[]>(`${URL_BASE_VERIFY}/subject/publish/${subjectId}`).pipe(
            map(items => partitionBySeverity(items)),
            catchError(error => this._serverError(error)),);
    }

    verifyCourseExtended(courseId: string): Observable<SortedCheckResults[]> {
        return this.http.get<CheckedResource[]>(`${URL_BASE_VERIFY}/extended/${courseId}`).pipe(
            map(items => partitionBySeverity(items)),
            catchError(error => this._serverError(error)),);
    }

    verifyScriptForPublish(scriptId: string): Observable<Anomaly[]> {
        return this.http.get<Anomaly[]>(`${URL_BASE_VERIFY}/exercise-script/${scriptId}`).pipe(
            catchError(error => this._serverError(error)));
    }

    verifyExerciseForPublish(exerciseId: string): Observable<Anomaly[]> {
        return this.http.get<Anomaly[]>(`${URL_BASE_VERIFY}/exercise/${exerciseId}`).pipe(
            catchError(error => this._serverError(error)));
    }
}