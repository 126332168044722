
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

import {
    CmsExerciseScript, CmsFolder, CmsTopicRef, CmsExerciseScriptRef, ReferencedBy, CmsTopicAttributeAssignment, VersionNumbers, CmsItemState
} from '../types/navigator.types';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../security/services/user.service';
import { PublishedExerciseScript } from '../types/published.types';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';
import { QuestionMode } from '../types/cms2-metadata.types';

const URL_BASE_EXERCISE_SCRIPT = "api/exercise-scripts";
const URL_BASE_EXERCISE_FROM_REF = "api/exercise-scripts/from-reference";
const URL_BASE_FOR_TOPIC = "api/exercise-scripts/topic";
const URL_BASE_EXERCISE_SCRIPT_SEARCH = "api/exercise-scripts/search";
const URL_BASE_PUBLISH_EXERCISE_SCRIPT = "/api/publish/exercise-script";
const URL_BASE_LATEST_VERSION_NUMBERS = `/api/versioning/numbers/latest/by-ids/library`;

@Injectable()
export class ExerciseScriptService extends AuthoringService {

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected userService: UserService,
        protected concurrencyService: ConcurrencyService
    ) {
        super(http, toastr, concurrencyService);
    }

    public lockResourceExceptReadOnly(id: string): Promise<Response> {
        return this.userService.canEditLibrary()
            ? this.lockResource(id)
            : Promise.resolve(null);
    }

    getExerciseScriptsForTopic(parent: CmsFolder<CmsTopicRef, CmsExerciseScriptRef>, onlyPublished?: boolean): Observable<CmsExerciseScript[]> {
        let url: string = onlyPublished
            ? URL_BASE_FOR_TOPIC + '/' + parent.id + '/published'
            : URL_BASE_FOR_TOPIC + '/' + parent.id;
        return this.http.get(url).pipe(
            map((arr: any[]) => {
                let itemArr: CmsExerciseScript[] = arr
                    .map(elm => this._getExerciseFromJson(elm));
                return itemArr;
            }),
            catchError(error => this._serverError(error)));
    }

    getExercises(ids: string[]): Observable<CmsExerciseScript[]> {
        let url = URL_BASE_EXERCISE_SCRIPT + '/retrieve-all';
        return this.http.post(url, ids).pipe(
            map((list: any[]) => {
                return list.map(obj => this._getExerciseFromJson(obj));
            }),
            catchError(error => this._serverError(error)));
    }

    getExercise(id: string): Observable<CmsExerciseScript> {
        return this.http.get(`${URL_BASE_EXERCISE_SCRIPT}/instance/${id}`).pipe(
            map((obj: any) => this._getExerciseFromJson(obj)),
            catchError(error => this._serverError(error)));
    }

    getExerciseFromReference(referenceid: string): Observable<CmsExerciseScript> {
        return this.http.get(`${URL_BASE_EXERCISE_FROM_REF}/${referenceid}`).pipe(
            map((obj: any) => this._getExerciseFromJson(obj)),
            catchError(error => this._serverError(error)));
    }

    getAttributeAssignmentsForScripts(ids: string[]): Observable<{ [scriptId: string]: CmsTopicAttributeAssignment[] }> {
        return this.http.post(`${URL_BASE_EXERCISE_SCRIPT}/attributes`, ids).pipe(
            map((obj: any) => obj as { [scriptId: string]: CmsTopicAttributeAssignment[] }),
            catchError(error => this._serverError(error)));
    }

    search(subtreeRootId: string, searchString: string, lastSearchToken: string) {
        return this.http.get(`${URL_BASE_EXERCISE_SCRIPT_SEARCH}/${subtreeRootId}/${searchString}/${lastSearchToken}`).pipe(
            catchError(error => this._serverError(error)));
    }

    storeExercise(exercise: CmsExerciseScript): Promise<string | any> {
        let storeObj = {
            ...exercise,
            definition: exercise.definition
            //            definition: this._getAmazonObjectFromCMSExercise(exercise.definition)
        }
        return this.http.post(`${URL_BASE_EXERCISE_SCRIPT}/instance`, storeObj, { responseType: 'text' })
            .toPromise()
            .catch(this._serverError);
    }

    moveExerciseScripts(exerciseIds: string[], topicId: string) {
        console.log("move ", exerciseIds, " to topic ", topicId);
        return this.http.post(URL_BASE_EXERCISE_SCRIPT + "/move", {
            exerciseScriptIds: exerciseIds,
            topicId: topicId,
        }).toPromise()
            .catch(error => this._serverError(error));
    }

    deleteExercise(id: string): Promise<boolean | any[]> {
        return this.http.delete(`${URL_BASE_EXERCISE_SCRIPT}/instance/${id}`).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError);
    }

    restoreExercise(id: string): Promise<boolean | any[]> {
        return this.http.post(`${URL_BASE_EXERCISE_SCRIPT}/instance/${id}/restore`, {}).pipe(
            map(() => true)).toPromise()
            .catch(this._serverError);
    }

    createExercise(topic: CmsTopicRef): CmsExerciseScript {
        return {
            id: null,
            name: 'new exercise',
            topic: topic,
            attributeAssignments: [],
            definition: {
                type: 'EXERCISE',
                audience: null,
                questionMode: QuestionMode.ONE_BY_ONE,
                elements: []
            }
        };
    }

    getReferencedExercises(id: string): Observable<ReferencedBy[]> {
        return this.http.get<ReferencedBy[]>(`${URL_BASE_EXERCISE_SCRIPT}/get-referenced-exercises/${id}`).pipe(
            map(result => {
                let references: ReferencedBy[] = [];
                for (let item of result) {
                    references.push({ item: item.item, type: item.type });
                }
                return references;
            }),
            catchError(error => this._serverError(error)));
    }

    checkPotentialFilterChanges(exercise: CmsExerciseScript): Promise<boolean> {
        return this.http.post<boolean>(`${URL_BASE_EXERCISE_SCRIPT}/check-potential-filter-changes/`, exercise)
            .toPromise();
    }

    publish(id: string): Promise<PublishedExerciseScript> {
        return this.http.post<PublishedExerciseScript>(`${URL_BASE_PUBLISH_EXERCISE_SCRIPT}/${id}`, {}).pipe(
            map(resp => this._getPublishedExerciseFromJson(resp)))
            .toPromise();
    }

    getPublished(id: string, majorVersion?: number): Promise<PublishedExerciseScript> {
        let url = majorVersion
            ? `${URL_BASE_PUBLISH_EXERCISE_SCRIPT}/${id}/${majorVersion}`
            : `${URL_BASE_PUBLISH_EXERCISE_SCRIPT}/${id}`
        return this.http.get(url).pipe(
            map(resp => {
                return resp
                    ? this._getPublishedExerciseFromJson(resp)
                    : null;
            }))
            .toPromise();
    }

    copyExercise(id: string) {
        return this.http.post(`${URL_BASE_EXERCISE_SCRIPT}/copy/${id}`, {}, {responseType:'text'})
            .toPromise()
            .catch(this._serverError);
    }

    copyExerciseScripts(ids: string[]) {
        return this.http.post(`${URL_BASE_EXERCISE_SCRIPT}/copy`, ids,  {responseType:'text'})
            .toPromise()
            .catch(this._serverError);
    }

    getLatestInstanceVersionNumbers(script: CmsExerciseScript): Promise<VersionNumbers> {
        return this.getLatestVersionNumbers([script])
            .then(result => result[script.id]);
    }

    getLatestVersionNumbers(scripts: CmsExerciseScript[]): Promise<{ [id: string]: VersionNumbers }> {
        if (!scripts || scripts.length == 0) {
            return Promise.resolve({});
        }
        let ids: string[] = [];
        for (let script of scripts) {
            if (script.state != CmsItemState.APPROVED) {
                continue;
            }
            ids.push(script.id);
        }
        if (ids.length === 0) {
            return Promise.resolve({});
        }
        return this.http.post<{ [id: string]: VersionNumbers }>(URL_BASE_LATEST_VERSION_NUMBERS, ids)
            .toPromise();
    }

    _getPublishedExerciseFromJson(obj: any): PublishedExerciseScript {
        let result = {
            ...obj,
            definition: JSON.parse(obj.definition)
        }
        return result;
    }

    _getExerciseFromJson(obj: any): CmsExerciseScript {
        let definition = obj.definition;
        let result: CmsExerciseScript = {
            ...obj,
            id: obj.id,
            name: obj.name,
            topic: obj.topic,
            attributeAssignments: obj.attributeAssignments,
            owner: obj.owner,
            created: obj.created,
            modified: obj.modified,
            state: obj.state,
            comment: obj.comment,
            description: obj.description,
            definition: definition,
            testSessions: obj.testSessions,
            tested: obj.tested,
            publishedMajorVersion: obj.publishedMajorVersion,
            interactionTypes: obj.interactionTypes,
            contentVersion: obj.contentVersion
        };
        return result;
    }

}
