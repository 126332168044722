/**
 * These override the default settings defined in the ProfileSettings class
 */
const PROFILE_SETTINGS = {
    staging: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    },
    dev: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    },
    staging_detached: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    },
    local: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'localhost:8091/console'
    },
    local_prod_db: {
        managementUrl: 'localhost:8091/console'
    },
    production: {
        managementUrl: 'management.algebrakit.com/console'
    },
    test: {
        publishingWarning: true,
        refArrangementWarning: true, 
        managementUrl: 'management.algebrakit.com/console'
    }
}

export class ProfileSettings {

    //Default settings
    public publishingWarning: false;       //Show a warning when publishing
    public refArrangementWarning: false;   //Show a warning when editing references and arrangements

    constructor(profileKey: string) {
        let obj = PROFILE_SETTINGS[profileKey];
        if (!obj) {
            return;
        }
        for (let key of Object.keys(obj)) {
            this[key] = obj[key];
        }
    }
}