
import { switchMap, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewContainerRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import {
    CmsExerciseScript, CmsCourse, CmsExerciseFilter, CmsSubject, CmsTopic, CmsTopicFilter,
    CmsTopicFilterLevelAttributeAssignement, CmsTopicFilterLevel, CmsAttributeAssignmentType, CmsLeaf, CmsTopicAttribute, CmsContainerType, RefConverter, CmsExerciseReference, CmsExerciseScriptRef, CmsTopicAttributeAssignment, CmsItemState, CmsVersioningInfo, CmsUpdateType, CmsCourseAudience
} from '../../types/navigator.types';
import { TopicService } from '../../services/topic.service';
import { CourseService } from '../../services/course.service';
import { MetadataService } from '../../../algebrakit/services/metadata.service';
import { AudienceSpec } from '../../../algebrakit/types/metadata.type';
import { ExerciseRef, CmsExerciseType } from '../../types/cms-metadata.types';
import { SubjectService } from '../../services/subject.service';
import { ExerciseScriptService } from '../../services/exercise-script.service';
import { ExerciseService } from '../../services/exercise.service';
import { Sorting } from '../../util/sorting';
import { UserService } from '../../../security/services/user.service';
import { ExerciseScriptPreviewComponent } from '../../components/exercise-script-preview/exercise-script-preview.component';
import { ComponentCanDeactivate } from '../../../app/services/component-can-deactivate';
import { TypeHelpers } from '../../util/type-helpers';
import { VersioningService } from '../../services/versioning.service';
import { PublishedExerciseReference, PublishedExerciseScript } from '../../types/published.types';
import { ICMS2ExerciseSpec } from '../../types/cms2-metadata.types';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { AkitShortcutComponent } from '../../components/akit-shortcuts/akit-shortcuts.component';
import { ContentVersionService } from '../../../app/services/content-version-service';

declare let $: any;
let subscriptions: Subscription[] = [];
declare let AlgebraKIT: any;

@Component({
    templateUrl: './exercise-reference-editor.component.html',
    styleUrls: ['./exercise-reference-editor.component.css', '../../../../assets/shared.less']
})
export class ExerciseReferenceEditorComponent extends ComponentCanDeactivate implements OnInit, OnDestroy, AfterViewInit {
    form: FormGroup;
    nameCtrl = new FormControl('', Validators.required);
    titleCtrl = new FormControl('');
    audienceCtrl = new FormControl('', Validators.required);
    descriptionCtrl = new FormControl('');
    commentCtrl = new FormControl('');
    audiences: CmsCourseAudience[];
    updateTypeControl = new FormControl('');
    commitMessageControl = new FormControl('', [this.commitMessageValidator]);

    exercise: CmsExerciseReference;
    oldExercise: CmsExerciseReference;
    exerciseReady: boolean = false;
    viewReady: boolean = false;
    scriptReady: boolean = false;

    demoExercise: CmsExerciseScript;
    course: CmsCourse;
    subject: CmsSubject;

    topic: CmsTopic = null;
    topicId: BehaviorSubject<string> = new BehaviorSubject(null);

    exerciseScriptId: BehaviorSubject<string> = new BehaviorSubject(null);
    exerciseScript: CmsExerciseScript;

    refreshTimer: any;

    versionInfo: CmsVersioningInfo;
    lastPublishedVersion: PublishedExerciseReference;
    publishedScript: PublishedExerciseScript;
    lastPublishedRetrieved: boolean = false;
    publishedDemo: boolean;

    metadataValid = true;

    changed: boolean;

    // bool to show title information in general settings tab
    generalExtend = false;

    showEditingWarning = false;
    publishingWarning = false;

    confirmPublish = false;

    @ViewChild('previewComponent', { static: false }) previewComponent: ExerciseScriptPreviewComponent;

    onCloseUrl: string;

    //To prevent "are you sure...." popup box from appearing twice
    leaveMessageAlreadyGiven: boolean;

    currentVersion: string;

    get saveTitle() {
        return `Save this exercise (${AkitShortcutComponent.getSaveShortcut()})`
    }

    get runTitle() {
        return `Run this exercise (${AkitShortcutComponent.getRunShortCut()})`
    }

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private topicService: TopicService,
        private exerciseService: ExerciseService,
        private courseService: CourseService,
        private subjectService: SubjectService,
        private exerciseScriptService: ExerciseScriptService,
        private metadataService: MetadataService,
        private versioningService: VersioningService,
        private userService: UserService,
        private appProfileService: AppProfileService,
        private contentVersionService: ContentVersionService
    ) {
        super();
        this.form = this.formBuilder.group({
            name: this.nameCtrl,
            title: this.titleCtrl,
            audience: this.audienceCtrl,
            description: this.descriptionCtrl,
            comment: this.commentCtrl,
            commitMessage: this.commitMessageControl,
            updateType: this.updateTypeControl,
        })
    }

    commitMessageValidator() {
        return (control: AbstractControl): { [key: string]: any } => {
            return this.exercise.state == CmsItemState.APPROVED
                && (!control.value || ("" + control.value).trim() == "")
                ? { 'emptyCommitMessage': { value: control.value } }
                : null;
        };
    }

    cancel() {
        if (this.changed) {
            if (confirm("You have unsaved changes in this exercise. Are you sure you want to close the editor?")) {
                this.leaveMessageAlreadyGiven = true;
                this.leave();
            }
        }
        else {
            this.leave();
        }
    }

    leave() {
        this.releaseLock();
        if (this.onCloseUrl == '_BACK') {
            this.location.back();
        }
        else if (this.onCloseUrl) {
            this.router.navigate([this.onCloseUrl]);
        }
        else {
            this.router.navigate(['subject', this.exercise.subject.id]);
        }
    }

    releaseLock(): void {
        this.exerciseService.releaseLock(this.exercise.id);
    }

    commit() {
        if (this.exercise.state == 'APPROVED') {
            let commitMsg: string = this.form.value.commitMessage;
            if (commitMsg == null || commitMsg.trim() == "") {
                alert('You need to provide a commit message when saving approved exercises.');
                return;
            }
        }

        this.exercise = this._getFormValue();
        this.exerciseService.storeExerciseReference(this.exercise)
            .then(() => {
                this.toastr.success("Exercise was stored successfully.", 'Success')
                this.exercise = this._getFormValue();
                this.oldExercise = TypeHelpers.clone(this.exercise);
                this.getVersioningData();
                this.commitMessageControl.setValue("");
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while storing the exercise", 'Error');
            });
    }

    setSubject(id: string): void {
        this.subjectService.getSubject(id).toPromise()
            .then(subject => {
                this.subject = subject;
                this.exercise.subject = {
                    id: subject.id, name: subject.name, containerType: CmsContainerType.FOLDER
                }
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while retrieving subject", 'Error');
            });
    }

    async preview(published?: boolean) {
        let demoExercise = null;
        let theme = this.course.options && this.course.options.theme
            ? this.course.options.theme
            : 'akit';
        await AlgebraKIT._api.changeTheme(theme);
        if (published) {
            this.publishedDemo = true;
            demoExercise = {
                definition: this.publishedScript.definition,
                name: this.publishedScript.name,
                id: this.publishedScript.id,
                attributeAssignments: [],
                topic: null,
                contentVersion: this.publishedScript.contentVersion
            }
            demoExercise.definition.audience = this.lastPublishedVersion.audience;
            demoExercise.definition.course = this.lastPublishedVersion.course;
        }
        else {
            this.publishedDemo = false;
            demoExercise = { ...this.exerciseScript }
            demoExercise.definition.audience = this.audienceCtrl.value;
            demoExercise.definition.course = this.exercise.course.id;
        }
        this.demoExercise = demoExercise;
        var modal = $('.exercise-demo').first();
        modal.modal('show');
    }

    refreshTest() {
        this.previewComponent.refresh();
    }

    setTopic(id: string) {
        this.topicId.next(id);
    }

    selectExercise(id: string) {
        this.exerciseScriptId.next(id);
    }

    //required to prevent that breadcrumb is destroyed and created whenever a new parent is chosen
    indexTrackId(index: number, goal: CmsTopic) {
        return index;
    }

    getColorForAttribute(attribute: CmsTopicAttributeAssignment): { [key: string]: string } {
        return this.topicService.getAttributeColorBg(attribute.difficulty);
    };

    setMetadataValid(valid: boolean) {
        this.metadataValid = valid;
    }

    tryGetOldValue() {
        if (this.viewReady && this.exerciseReady && this.scriptReady) {
            let exercise = this._getFormValue();
            this.oldExercise = TypeHelpers.clone(exercise);
        }
    }

    canDeactivate(): boolean {
        let oldEx = { ...this.oldExercise };
        let newEx = { ...this._getFormValue() };
        delete oldEx.commitMessage;
        delete oldEx.updateType;
        delete newEx.commitMessage;
        delete newEx.updateType;
        this.changed = !TypeHelpers.itemsEqual(oldEx, newEx);
        return this.leaveMessageAlreadyGiven || !this.changed;
    }

    approveButtonVisible() {
        return this.canApprove() && this.exercise.state == 'DRAFT' && this.canDeactivate() && this.exerciseScript && this.form.valid && this.metadataValid;
    }

    canApprove(): boolean {
        return this.userService.canApproveCourseExercises(this.course);
    }

    canPublish(): boolean {
        return this.userService.canPublishInCourse(this.exercise.course) && this.exercise.state == 'APPROVED';
    }

    publishButtonVisible() {
        return this.exercise.state == 'APPROVED' && this.lastPublishedRetrieved && this.canPublish()
    }

    approve() {
        if (confirm("You are about to move this exercise from the DRAFT to the APPROVED state and enable versioning for this exercise. Are you sure?")) {
            this.versioningService.approve(this.exercise.id)
                .then((exercise: CmsExerciseReference) => {
                    this.exercise.state = CmsItemState.APPROVED;
                    this.getVersioningData();
                    this.oldExercise = TypeHelpers.clone(this._getFormValue());
                    this.toastr.success("Exercise is now approved.", 'Success');
                })
                .catch((err: any) => {
                    console.log(err);
                    this.showErrorMessage(err, "Error occurred while approving the exercise");
                })
        }
    }

    publish() {
        if (this.publishingWarning) {
            this.confirmPublish = true;
        }
        else {
            this.doPublish();
        }
    }

    cancelPublish() {
        this.confirmPublish = false;
    }

    doPublish() {
        this.exerciseService.publish(this.exercise, this.versionInfo, this.lastPublishedVersion)
            .then((pubEx) => {
                this.confirmPublish = false;
                if (!pubEx) {
                    //Aborted, do nothing.
                    return;
                }
                this.lastPublishedVersion = pubEx as PublishedExerciseReference;
                this.getVersioningData();
            })
    }

    showErrorMessage(err: any, fallBackMessage: string) {
        let message = err.message || err.message || fallBackMessage;
        this.toastr.error(message, 'Error');
    }

    getVersioningData() {
        if (this.exercise.state == CmsItemState.APPROVED) {
            this.versioningService.getLatestVersionInfo(this.exercise.id)
                .then((versionInfo: CmsVersioningInfo) => {
                    this.versionInfo = versionInfo
                });
        }
    }

    getEditorIcon() {
        return `<span class="fa fa-edit"></span>`;
    }

    getLastPublish() {
        this.lastPublishedRetrieved = false;
        if (this.exercise.state == CmsItemState.DRAFT) {
            this.lastPublishedRetrieved = true;
            return Promise.resolve(null);
        }
        this.exerciseService.getPublishedReference(this.exercise.id, this.exercise.publishedMajorVersion).then((pubEx) => {
            this.lastPublishedVersion = pubEx as PublishedExerciseReference;
            let promise = this.lastPublishedVersion
                ? this.exerciseScriptService.getPublished(this.lastPublishedVersion.exerciseScriptId, this.lastPublishedVersion.exerciseScriptMajorVersion)
                : Promise.resolve(null);
            promise.then((pubScript) => {
                this.publishedScript = pubScript;
                this.lastPublishedRetrieved = true;
            });
        });
    }

    isEmptyMetadata() {
        return !this.exercise || !this.exercise.metadata || this.exercise.metadata.length == 0;
    }

    compareVersionNumbers() {
        if (!this.versionInfo || !this.lastPublishedRetrieved) {
            return -1;
        }
        if (!this.lastPublishedVersion) {
            return 1;
        }
        let compareResult = TypeHelpers.compareVersionNumbers(
            this.versionInfo.majorVersion,
            this.versionInfo.minorVersion,
            this.lastPublishedVersion.majorVersion,
            this.lastPublishedVersion.minorVersion
        );
        return compareResult;
    }

    getPublishButtonTitle() {
        if (!this.canPublish()) {
            return 'Please save the exercise first';
        }
        if (this.compareVersionNumbers() === 0) {
            return 'This version is already published';
        }
        if (this.compareVersionNumbers() === -1) {
            return 'A newer version of this exercise has already been published';
        }
        return 'Publish this exercise';
    }

    ngAfterViewInit(): void {
        this.viewReady = true;
        this.tryGetOldValue();
    }

    ngOnInit() {
        this.contentVersionService.getContentVersion().then(contentVersion => this.currentVersion = contentVersion);
        this.appProfileService.getSetting('refArrangementWarning').then((value) => {
            this.showEditingWarning = value;
        });
        this.appProfileService.getSetting('publishingWarning').then((value) => {
            this.publishingWarning = value;
        });

        this.route.queryParams
            .subscribe((params: Params) => {
                this.onCloseUrl = params['onCloseUrl'];
                console.log(this.onCloseUrl);
            });

        this.route.data
            .subscribe((data: { exercise: CmsExerciseReference, course: CmsCourse }) => {
                this.leaveMessageAlreadyGiven = false;
                if (data.exercise) {
                    this.contentVersionService.showVersionAlert();
                    this.refreshTimer = this.exerciseService.scheduleRefresh(data.exercise.id);
                    this.exercise = data.exercise;
                    this.getLastPublish();
                    if (this.exercise.exerciseScript) {
                        this.exerciseScriptId.next(this.exercise.exerciseScript.id);
                    }
                    this.courseService.getCourse(data.exercise.course.id).toPromise().then(course => {
                        this.course = course;
                        this.audiences = course.audiences;
                        this.form.patchValue({
                            name: data.exercise.name,
                            title: data.exercise.title,
                            audience: data.exercise.audience,
                            description: data.exercise.description,
                            comment: data.exercise.comment
                        });
                        this.getVersioningData();
                        this.exerciseReady = true;
                        this.tryGetOldValue();
                    });

                    this.subjectService.getSubject(this.exercise.subject.id)
                        .toPromise().then(subject => this.subject = subject);
                }
            });

        subscriptions.push(
            this.audienceCtrl.valueChanges
                .subscribe(id => {
                    this.exercise.audience = id;
                })
        );

        this.exerciseScriptId.pipe(
            filter(id => {
                if (id == null) {
                    this.scriptReady = true;
                }
                return id !== null
            }),
            switchMap(id => this.exerciseScriptService.getExercise(id)))
            .subscribe(exerciseScript => {
                this.exerciseScript = exerciseScript;
                this.topicId.next(exerciseScript.topic.id);
                this.scriptReady = true;
                if (!this.oldExercise) {
                    this.tryGetOldValue();
                }
            });

        this.topicId.pipe(
            filter(id => id !== null),
            switchMap(id => this.topicService.getTopic(id, true)))
            .subscribe((topic: CmsTopic) => {
                this.topic = topic;
            });

        this.topicService.getRootTopic().toPromise().then((rootTopic) => {
            this.topic = rootTopic;
        });

        // show extended settings if they are present
        this.generalExtend = (this.exercise.comment != null && this.exercise.comment.length > 0)
            || (this.exercise.description != null && this.exercise.description.length > 0)
            || (this.exercise.title != null && this.exercise.title.length > 0);

    }

    ngOnDestroy() {
        for (let s of subscriptions) s.unsubscribe();
        this.exerciseService.descheduleRefresh(this.refreshTimer);
        this.exerciseService.releaseLock(this.exercise.id);
    }

    _getFormValue(): CmsExerciseReference {
        let obj = this.form.value;
        let exercise: CmsExerciseReference = { ...this.exercise };
        exercise.title = obj.title;
        exercise.name = obj.name;
        exercise.description = obj.description;
        exercise.comment = obj.comment;
        exercise.exerciseScript = this.exerciseScript ? RefConverter.tocExerciseScriptRef(this.exerciseScript) : null;
        exercise.updateType = CmsUpdateType[this.updateTypeControl.value] as CmsUpdateType || CmsUpdateType.MINOR;
        exercise.commitMessage = obj.commitMessage || "";
        return exercise;
    }

}
