
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { I18nLabel, I18nLabelValue } from '../types/cms-metadata.types';
import { AsyncSubject ,  BehaviorSubject } from 'rxjs';
import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { HttpClient } from '@angular/common/http';

const URL_LABELS = "api/labels";

@Injectable()
export class I18nLabelService extends AuthoringService {

    public labels$: BehaviorSubject<I18nLabel[]> = new BehaviorSubject([]);

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService
    ) { 
        super(http, toastr, concurrencyService);
        this.refreshLabels();
    }

    public getLabel(id: string) {
        return this.http.get(`${URL_LABELS}/instance/${id}`).pipe(
            map((obj) => this._convertLabel(obj)))
            .toPromise()
            .catch(error => this._serverError(error));
    }

    public saveLabel(label: I18nLabel): Promise<string | any[]> {
        return this.http.post(`${URL_LABELS}/instance`, label, {responseType:'text'})
            .toPromise()
            .catch(error => this._serverError(error))
    }

    public deleteLabel(id: string) {
        return this.http.delete(`${URL_LABELS}/instance/${id}`).toPromise()
            .catch(error => this._serverError(error));
    }

    public getLabels() {
        return this.http.get(URL_LABELS).pipe(
            map((obj: any[]) => {
                let labels: I18nLabel[] = [];
                for (let item of obj) {
                    let label: I18nLabel = this._convertLabel(item);
                    labels.push(label);
                }
                return labels;
            }),
            catchError(error => this._serverError(error)),);
    }

    public getVersionInfo() {
        return this.http.get(URL_LABELS).pipe(
            map((obj: any[]) => {
                let labels: I18nLabel[] = [];
                for (let item of obj) {
                    let label: I18nLabel = this._convertLabel(item);
                    labels.push(label);
                }
                return labels;
            }),
            catchError(error => this._serverError(error)),);
    }

    public refreshLabels(): Promise<I18nLabel[]> {
        return this.getLabels().toPromise()
            .then(labels => {
                this.labels$.next(labels);
                return labels;
            },
            err => {
                console.log("Could not fetch i18n-labels");
                console.log(err);
                this.labels$.error(err);
                throw err;
            });
    }

    createLabel(): I18nLabel {
        let result: I18nLabel = {
            id: '',
            items: {}
        }
        return result;
    }

    private _convertLabel(label: any): I18nLabel {
        /*let items: { [lang: string]: I18nLabelValue } = {};
        for (let item of label.items) {
            items[item.id.lang] = {
                lang: item.id.lang,
                text: item.text
            }
        }
        return {
            id: label.id,
            items: items,
            modifiedBy: label.modifiedBy,
            modifiedDate: label.modifiedDate ? new Date(label.modifiedDate) : null
        }*/
        return label as I18nLabel;
    }
}
