// @ts-nocheck
import { platformBrowser } from '@angular/platform-browser';
import { enableProdMode } from '@angular/core';

import { BootService } from './app/security/services/boot-service';
import { AppModuleNgFactory } from './app/app/app.module.ngfactory';
import { IAlgebraKIT } from './app/algebrakit/types/algebrakit.type';

let faviconProd = require("./assets/images/favicon-production.png");
let faviconTest = require("./assets/images/favicon-test.png");
let faviconStaging = require("./assets/images/favicon-staging.png");

declare const AlgebraKIT: IAlgebraKIT;

declare let AKIT_CMS_PROFILE: string;
declare let $: any;

const iconProfiles = {
  'test': faviconTest,
  'production': faviconProd,
  'staging': faviconStaging,
  'staging_detached': faviconTest,
}

function getIconUrl() {
  if (AKIT_CMS_PROFILE == undefined) {
    AKIT_CMS_PROFILE = "";
  }
  let iconUrl = iconProfiles[AKIT_CMS_PROFILE];
  return iconUrl ? iconUrl : faviconProd;
}

$('document').ready(function () {
  $('link[rel="icon"]').attr('href', getIconUrl());
})

require("./styles.css");

enableProdMode();

const init = async () => {
  const akitwidgetsScriptAttr = 'data-akit-widgets';

  //Load widget scripts if necessary
  let widgetResolve: (value?: any) => void;
  const widgetLoadPromise = new Promise(resolve => {
    widgetResolve = resolve;
  })
  //If script tag with "data-akit-widgets" is absent, add it dynamically using a cookie
  var widgetsScriptTag = document.querySelector(`script[${akitwidgetsScriptAttr}]`);
  if (!widgetsScriptTag) {
    var fallback = "https://widgets.algebrakit.com" //If cookie is not set, fallback to prod
    let cookie = document.cookie;
    let host;
    if (cookie) {
      let cookieRows = cookie.split(/[\n;]/);
      let entry = cookieRows.find(row => row.includes('WIDGETS-HOST='));
      if (entry) {
        host = entry.split('=')[1].trim();
      }
    }

    if (!host || host.length == 0) {
      host = fallback;
    }
    let script = document.createElement('script');
    script.setAttribute(akitwidgetsScriptAttr, '');
    script.src = `${host}/akit-widgets.min.js`;

    script.addEventListener('load', widgetResolve);

    document.body.appendChild(script);
  }
  else {
    widgetResolve();
  }

  await widgetLoadPromise;
  await AlgebraKIT.initializedPromise;

  platformBrowser().bootstrapModuleFactory(AppModuleNgFactory);
}

// Init on first load
init();

// Init on reboot request
const boot = BootService.getbootControl().watchReboot().subscribe(() => init());
