import { NgModule }      from '@angular/core';
import { RouterModule }  from '@angular/router';

import {TopicNavigatorComponent} from './containers/topic-navigator/topic-navigator.component';
import {CourseNavigatorComponent} from './containers/course-navigator/course-navigator.component';
import { TopicEditorComponent }  from './containers/topic-editor/topic-editor.component';
import { SubjectEditorComponent }  from './containers/subject-editor/subject-editor.component';
import { CourseEditorComponent }  from './containers/course-editor/course-editor.component';
import { ExerciseEditorComponent }  from './containers/exercise-editor/exercise-editor.component';
import {ExerciseResolverService} from './services/exercise-resolver.service';
import {ExerciseFilterResolverService} from './services/exercise-filter-resolver.service';
import { TestAlgebraComponent } from './containers/test-algebra/test-algebra.component';
import { AuthUserGuard } from '../app/services/auth-user-guard.service';
import { CourseOverviewComponent }      from './containers/course-overview/course-overview.component';
import { ExerciseFilterEditorComponent } from './containers/exercise-filter-editor/exercise-filter-editor.component';
import { CourseResolverService }        from './services/course-resolver.service';
import { ExerciseReferenceEditorComponent } from './containers/exercise-reference-editor/exercise-reference-editor.component';
import { ExerciseReferenceResolverService } from './services/exercise-reference-resolver.service';
import { ClientExerciseSpecEditor } from './containers/exercise-client-spec-editor/exercise-client-spec-editor.component';
import { ClientExerciseSpecResolverService } from './services/client-exercise-spec-resolver.service';
import { LabelsOverviewComponent } from './containers/labels-overview/labels-overview.component';
import { LabelEditorComponent } from './containers/label-editor/label-editor.component';
import { HelpComponent } from './containers/help/help.component';
import { ReferenceComponent } from './containers/reference/reference.component';
import { VersionViewerContainer } from './containers/version-viewer-container/version-viewer-container.component';
import { CanDeactivateGuard } from '../app/services/can-deactivate.guard.service';
import { AttributesOverviewComponent } from './containers/attributes-overview/attributes-overview.component';
import { TopicAttributeEditorComponent } from './containers/topic-attribute-editor/topic-attribute-editor.component';
import { TopicResolverService } from './services/topic-resolver.service';
import { SubjectResolverService } from './services/subject-resolver.service';
import { PublishSnapshotComponent } from './containers/publish-snapshot/publish-snapshot.component';
import { IdSearchComponent } from './containers/id-search/id-search.component';
import { ResourceRedirectorComponent } from './containers/resource-redirector/resource-redirector.component';
import { PublishSubjectComponent } from './containers/publish-subject/publish-subject.component';
import { ROLE_LABEL_EDITOR, ROLE_LIBRARY_EDITOR } from '../security/types/user.type';
import { VersionMismatchComponent } from './containers/version-mismatch/version-mismatch.component';

const routes = [
   {path: 'topics',  component: TopicNavigatorComponent, data: {sticky:true, title: "Library"}, canActivate: [AuthUserGuard] },
   {path: 'topic/:id',  component: TopicNavigatorComponent, data: {sticky:true, dynamicTitle: true}, resolve: { topic: TopicResolverService }, canActivate: [AuthUserGuard] },
   {path: 'subject/:id',  component: CourseNavigatorComponent, data: {sticky:true, dynamicTitle: true}, resolve: { subject: SubjectResolverService }, canActivate: [AuthUserGuard] },
   {path: 'courses',  component: CourseOverviewComponent, canActivate: [AuthUserGuard], data: {title: 'Courses'} },
   {path: 'id-search', component: IdSearchComponent, canActivate: [AuthUserGuard], data: {title: 'Search exercise ID'}},
   {path: 'id-search/:ids', component: IdSearchComponent, canActivate: [AuthUserGuard], data: {title: 'Search exercise ID'}},
   {path: 'snapshots',  component: PublishSnapshotComponent, data: {sticky:true, title: "Publish Course", roles: ['*.publish']}, canActivate: [AuthUserGuard] },
   {path: 'publish-subject/:id',  component: PublishSubjectComponent, canActivate: [AuthUserGuard], data: {sticky:true, title: "Publish folder", roles: ['*.publish']} },
   {path: 'history/:id',  component: VersionViewerContainer, canActivate: [AuthUserGuard], data: {title: 'Version History'} },
   {path: 'labels', component: LabelsOverviewComponent, canActivate: [AuthUserGuard], data: {title: 'Manage Labels'}},
   {path: 'attributes', component: AttributesOverviewComponent, canActivate: [AuthUserGuard], data: {title: 'Manage Attributes'}},
   {path: 'help', component: HelpComponent,  data: {title: 'Help Center'}},
   {path: 'help/:component/:section/:id', component: HelpComponent,  data: {title: 'Help Center'}},
   {path: 'help/:component/:id', component: HelpComponent,  data: {title: 'Help Center'}},
   {path: 'reference', component: ReferenceComponent, data: {title: 'Reference Guide'}},
   {path: 'reference/:component/:section/:id', component: ReferenceComponent, data: {title: 'Reference Guide'}},
   {path: 'reference/:component/:id', component: ReferenceComponent, data: {title: 'Reference Guide'}},
   {path: 'reference/:component', component: ReferenceComponent, data: {title: 'Reference Guide'}},
   {path: 'test-algebra', component: TestAlgebraComponent, canActivate: [AuthUserGuard], data: {title: 'Sandbox: Standalone Algebra'}},
   {path: 'edit/topic/:id',  component: TopicEditorComponent, canActivate: [AuthUserGuard], data: {title: 'Edit Topic'}},
   {path: 'edit/subject/:id',  component: SubjectEditorComponent, canActivate: [AuthUserGuard], data: {title: 'Edit Folder'}},
   {path: 'edit/course/:id',  component: CourseEditorComponent, resolve: { course: CourseResolverService }, canActivate: [AuthUserGuard], data: {title: 'Edit Course'}},
   {path: 'edit/label/:id',  component: LabelEditorComponent, canActivate: [AuthUserGuard], data: {title: 'Edit Label',  roles: [ROLE_LABEL_EDITOR]}},
   {path: 'edit/attribute/:id',  component: TopicAttributeEditorComponent, canActivate: [AuthUserGuard], data: {title: 'Edit Attribute', roles: [ROLE_LIBRARY_EDITOR]} },
   {path: 'create/attribute',  component: TopicAttributeEditorComponent, canActivate: [AuthUserGuard], data: {title: 'Create Attribute', roles: [ROLE_LIBRARY_EDITOR]}},
   {path: 'create/label',  component: LabelEditorComponent, canActivate: [AuthUserGuard], data: {title: 'Create Label', roles: [ROLE_LABEL_EDITOR]}},
   {path: 'edit/exercise/:id',  component: ExerciseEditorComponent, resolve: { exercise: ExerciseResolverService }, canActivate: [AuthUserGuard], canDeactivate: [CanDeactivateGuard], data: {dynamicTitle: true}},
   {path: 'edit/exercise-filter/:id',  component: ExerciseFilterEditorComponent, resolve: { exercise: ExerciseFilterResolverService }, canActivate: [AuthUserGuard], canDeactivate: [CanDeactivateGuard], data: {dynamicTitle: true}},
   {path: 'edit/exercise-reference/:id',  component: ExerciseReferenceEditorComponent, resolve: { exercise: ExerciseReferenceResolverService }, canActivate: [AuthUserGuard], canDeactivate: [CanDeactivateGuard], data: {dynamicTitle: true}},
   {path: 'edit/exercise-spec-client/:id',  component: ClientExerciseSpecEditor, resolve: { exercise: ClientExerciseSpecResolverService }, canActivate: [AuthUserGuard], canDeactivate: [CanDeactivateGuard], data: {dynamicTitle: true}},
   {path: 'edit/:id', component: ResourceRedirectorComponent, canActivate: [AuthUserGuard], data: {title: 'Edit'}},
   {path: 'version-mismatch', component: VersionMismatchComponent, canActivate: [AuthUserGuard], data: {title: 'Wrong version'}},
];

@NgModule({
  imports:      [ RouterModule.forChild(routes)  ],
  exports:      [ RouterModule ]
})
export class AuthoringRoutingModule { }


