
<div class="exercise-filter-preview" #previewTable>

    <div class='algebra-wrapper' *ngIf="multiplePreviews()">
        <div  [hidden]="pending">
            <div class="level-container" *ngIf="nrOfLevels>1">
                <span class="level-heading">Level</span>
                <div class="level-wrapper" *ngFor="let ll of levels" (click)="setLevel(ll)" [ngClass]="{'active': ll==level}">
                     <span>{{ll+1}}</span>
                </div>
            </div>
            <table class="akit-script-test"  >
                <tr class="tr-heading">
                    <th>Exercise</th>
                    <th>Solution</th>
                    <th></th>
                    <th></th>
                </tr>
                <tr *ngFor="let item of specPreviews | async" class="preview-row">
                    <td *ngIf="!item.viewSpec.success" class="td-exercise-fail" colspan="4">
                        <span class="fa fa-exclamation-circle"></span> Could not load preview: {{item.errorMessage || item.msg}}
                    </td>
                    <td *ngIf="item.viewSpec.success" class="td-exercise"><span class="katex-wrapper" [innerHTML]="item.instruction | safeHtml"></span></td>
                    <td *ngIf="item.viewSpec.success" class="td-exercise"><span class="katex-wrapper" [innerHTML]="item.solution | safeHtml"></span></td>
                    <td *ngIf="item.viewSpec.success"><button type="button" class="fa fa-play" (click)="insertWidgets(item.viewSpec)"></button></td>
                    <td *ngIf="item.viewSpec.success"><button *ngIf="(item.viewSpec.exerciseSpec?.scriptRef || item.viewSpec.exerciseSpec?.script) && showEditViewButtenForScripts" type="button" [ngClass]="getIconForEditViewButton()" (click)="editExercise(item.viewSpec.exerciseSpec)"></button></td>
                </tr>
            </table>
        </div>
        <div class="row akit-metadata-test" *ngIf="sessionId">
            <div class='col-sm-6 col-xs-12'>
                <cms-interaction [html]="interactionHTML"></cms-interaction>
            </div>
            <div class='col-sm-6 col-xs-12'>
                <cms-solution [appId]='appId' [sessionId]='sessionId'></cms-solution>
            </div>
        </div>
    </div>
    
    <div class='other-wrapper' *ngIf="!multiplePreviews()">
        <div class="level-container" *ngIf="nrOfLevels>1">
            <span class="level-heading">Level</span>
            <div class="level-wrapper" *ngFor="let ll of levels" (click)="setLevel(ll)" [ngClass]="{'active': ll==level}">
                 <span>{{ll+1}}</span>
            </div>
        </div>
        <cms-interaction [html]="interactionHTML"></cms-interaction>
    </div>    
    
    <busy-gears *ngIf="busy"></busy-gears>

</div>
