import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NameIdPair, AudienceSpec, AudienceResolveResult, AudienceMap, EditorAssetResolveResult, QuestionMode } from '../../algebrakit/types/metadata.type';
import { ArithmeticTemplate } from '../../authoring/types/cms-metadata.types';
import { CmsCourse, CmsCourseAudience } from '../../authoring/types/navigator.types';
import { AuthoredExercise, IAlgebraKIT } from '../types/algebrakit.type';

declare let AlgebraKIT: IAlgebraKIT;

let initPromise: Promise<any> = null;

const defaultOptions = {
    defaultAudience: {
        name: 'English',
        id: 'uk_KS5',
        languages: ['en']
    },
    defaultQuestionMode: QuestionMode.ALL_AT_ONCE,
    defaultEditor: 'equations',
    availableAudiences: [{
        name: 'English Key Stage 5',
        id: 'uk_KS5',
        languages: ['en']
    }, {
        name: 'English Key Stage 3',
        id: 'uk_KS3',
        languages: ['en']
    }, {
        name: 'Espagnol',
        id: 'spain',
        languages: ['es', 'en']
    }, {
        name: 'François',
        id: 'fr_vwo_b',
        languages: ['fr', 'en']
    }, {
        name: 'Deutsch',
        id: 'de_vwo_b',
        languages: ['de', 'en']
    }, {
        name: 'Nederlands Bovenbouw',
        id: 'vwo-b',
        languages: ['nl', 'en']
    }, {
        name: 'Nederlands Onderbouw',
        id: 'onderbouw',
        languages: ['nl', 'en']
    }]
}

export const COUNTRIES: NameIdPair[] = [
    { name: 'United Kingdom', id: 'en' },
    { name: 'Nederland', id: 'nl' },
    { name: 'Vlaanderen', id: 'nl-BE' },
    { name: 'Wallonie', id: 'fr-BE' },
    { name: 'Deutsch', id: 'de' },
    { name: 'Francais', id: 'fr' },
    { name: 'Espagnol', id: 'es' },
    { name: 'Lietuva', id: 'lt' },
    { name: 'Unspecified', id: 'others' }
];

export const PALETTES: NameIdPair[] = [
    { name: 'advanced', id: 'advanced' },
    { name: 'equations', id: 'equations' },
    { name: 'algebra', id: 'algebra' },
    { name: 'arithmetic', id: 'arithmetic' },
    { name: 'virtual only', id: 'virtualOnly' }
];

export const WIDGET_TYPES: NameIdPair[] = [
    { name: 'automatic', id: 'AUTOMATIC' },
    { name: 'arithmetic notebook', id: 'CALCBOOK' }
];
export const INSTRUCTION_TYPES: NameIdPair[] = [
    { name: 'Keyword and expression', id: 'KEYWORD_EXPRESSION' },
    { name: 'Algebrakit script', id: 'SCRIPT' }
];

export const EVALUATION_MODES: NameIdPair[] = [
    { name: 'Equivalent (default)', id: 'EQUIVALENT' },
    { name: 'Exact match', id: 'EXACT' },
    { name: 'Equivalent, no simplification', id: 'EQUIVALENT_NO_DERIVATION' }
];

export const STEP_ACTION_TYPES: NameIdPair[] = [
    { name: 'Calculate', id: 'CALCULATE' },
    { name: 'Write', id: 'WRITE' },
    { name: 'Find', id: 'FIND' }
]

export const ARITHMETIC_TYPES: NameIdPair[] = [
    { name: 'Sum', id: '' + ArithmeticTemplate.SUM },
    { name: 'Sum short form', id: '' + ArithmeticTemplate.SUM_SHORT },
    { name: 'Sum column form', id: '' + ArithmeticTemplate.SUM_COLUMNWISE },
    { name: 'Subtract', id: '' + ArithmeticTemplate.SUBTRACT },
    { name: 'Subtract short form', id: '' + ArithmeticTemplate.SUBTRACT_SHORT },
    { name: 'Subtract column form', id: '' + ArithmeticTemplate.SUBTRACT_COLUMNWISE },
    { name: 'Product', id: '' + ArithmeticTemplate.PRODUCT },
    { name: 'Product short form', id: '' + ArithmeticTemplate.PRODUCT_SHORT },
    { name: 'Product column form', id: '' + ArithmeticTemplate.PRODUCT_COLUMNWISE },
    { name: 'Division column form', id: '' + ArithmeticTemplate.DIVISION_COLUMNWISE }
];

export const ARITHMETIC_STREAMS: NameIdPair[] = [
    { name: 'Wereld in getallen', id: 'WIG' },
];

export const QUESTION_MODES = [
    { id: 'ONE_BY_ONE', name: 'One by one' },
    { id: 'ALL_AT_ONCE', name: 'All at once' }
]

export const RENDER_MODES = [
    { id: 'RAW', name: 'Plain' },
    { id: 'PREPROCESSED', name: 'Formatted' }
]

export const APP_ID = 'algebrakit-cms';

@Injectable()
export class MetadataService {

    private audiences: AudienceMap = null;

    public authenticatedPromise: Promise<void>;

    constructor(protected http: HttpClient) {
        this.resetUserPromise();
        initPromise = this.initAudiences();
    }

    public userAuthenticated: () => void;

    public resetUserPromise = () => {
        this.authenticatedPromise = new Promise((resolve) => {
            this.userAuthenticated = resolve
        })
    }

    async initAudiences(): Promise<void> {
        //Wait until there is an authenticated user
        await this.authenticatedPromise;
        if (this.audiences) {
            return Promise.resolve();
        }
        return this.http.get<AudienceMap>('api/audience/all')
            .toPromise()
            .then(data => {
                this.audiences = data;

                let keys = Object.keys(this.audiences).sort();

                for (let key of keys) {
                    let aud = this.audiences[key];
                    this.audiences[key].country = this.getCountryName(aud.languages[0]);
                    defaultOptions.availableAudiences.push({
                        name: aud.name? aud.name :aud.audienceID,
                        id: aud.audienceID,
                        languages: aud.languages
                    })
                }

                return;
            })
    }

    getAudiences(countryId?: string): Promise<AudienceSpec[]> {
        return this.initAudiences().then(() => {
            let audiences: AudienceSpec[] = Object.keys(this.audiences).map((e) => {
                return this.audiences[e]
            })
            return countryId
                ? audiences.filter(a => a.languages[0] && a.languages[0] == countryId)
                : audiences;
        });
    }

    getAudienceResolveList(courseId: string): Promise<AudienceResolveResult[]> {
        return this.http.get<AudienceResolveResult[]>('api/audience/resolve-list/' + courseId)
            .toPromise();
    }

    getEditorAssetResolveList(courseId: string): Promise<EditorAssetResolveResult[]> {
        return this.http.get<EditorAssetResolveResult[]>('api/formula-editors/resolve-list/' + courseId)
            .toPromise();
    }

    getAudience(id: string): AudienceSpec {
        return this.audiences[id];
    }

    getForCourseId(id: string): Promise<AudienceSpec[]> {
        return this.http.get<AudienceSpec[]>('api/audience/for-course/' + id)
            .toPromise();
    }

    getForCourse(course: CmsCourse): Promise<AudienceSpec[]> {
        let courseAudienceMap: { [id: string]: CmsCourseAudience } = {};
        return this.initAudiences().then(() => {
            if (course.audiences) {
                for (let a of course.audiences) {
                    let id = a.resolvedAudience
                        ? a.resolvedAudience
                        : a.baseAudienceId;
                    courseAudienceMap[id] = a;
                }
            }
            return Object.keys(courseAudienceMap)
                .map(a => {
                    let audience = this.audiences[a]
                    if (!audience.name) {
                        audience.name = courseAudienceMap[a].name;
                    }
                    return audience;
                })
                .filter(a => a != null);
        });
    }

    getAllSubjectAudiences(subjectId: string): Promise<string[]> {
        return this.http.get<string[]>('api/audience/get-for-subject/' + subjectId)
            .toPromise();
    }

    getCountries(audienceID: string) {
        let audience = this.audiences[audienceID];
        return audience ? audience.languages : [];
    }

    getCountryName(countryCode: string) {
        let country = COUNTRIES.find(c => c.id == countryCode);
        return country
            ? country.name
            : 'Unknown region';
    }

    getPreselectedAudiences(countryCode: string): Promise<AudienceSpec[]> {
        return this.http.get<AudienceSpec[]>('api/audience/pre-defined/' + countryCode)
            .toPromise();
    }

    async getExerciseManager(spec?: AuthoredExercise): Promise<any> {
        if(initPromise) {
            await initPromise;
        }

        if(spec) {
            let exerciseManager = AlgebraKIT.getExerciseManager(spec.id);
            if(exerciseManager) {
                return Promise.resolve(exerciseManager);
            } else {
                return AlgebraKIT.setExercise(spec, defaultOptions);
            }
        } else {
            let interactionName = 'TestInteraction';
            return AlgebraKIT
                .createExercise(defaultOptions)
                .then(exerciseManager => {
                    exerciseManager.addInteraction(0, interactionName);
                    return exerciseManager;
                });
        }
        
    }

    createEmptyExercise():Promise<AuthoredExercise> {
        return AlgebraKIT
                .createExercise(defaultOptions)
                .then(exerciseManager => {
                    exerciseManager.addInteraction(0, 'TestInteraction');
                    let spec = exerciseManager.getExercise();
                    return spec;
                });
    }


}
