import { Injectable } from '@angular/core';
import 'rxjs/Rx';

import { AuthoringService } from './authoring.service';
import { ToastrService } from 'ngx-toastr';
import { Mode } from '../types/cms2-metadata.types';
import { ConcurrencyService } from '../../app/services/concurrency.service';
import { UserService } from '../../security/services/user.service';
import { checkPermissions } from '../../security/services/permission-utils';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ModesService extends AuthoringService {

    private modes: Mode[];

    constructor(
        protected http: HttpClient,
        protected toastr: ToastrService,
        protected concurrencyService: ConcurrencyService,
        protected userService: UserService
    ) { 
        super(http, toastr, concurrencyService);
    }

    getModes(): Mode[] {
        if (this.modes) {
            return this.modes;
        }
        let user = this.userService.getUser();
        let canViewAllModes = checkPermissions(user, ['global.modes.can-use-internal']);
        var allModes: Mode[] = require('../../../assets/json/modes.json') as Mode[];
        this.modes = allModes
            .filter(m => canViewAllModes || m.internal != "internal")
            .filter(m => !m.deprecated || m.deprecated == "")
        return this.modes;
    }
}