<div class='course-navigator'>
    <div class="row display-flex-center">
        <div class="col-xs-9">
            <h1>Courses</h1>
        </div>
        <div class="col-xs-3">
            <div class="float-right" *ngIf="showCreateButton">

                <button class="btn btn-primary create-button pull-right" (click)="createCourse()">
                    <span translate>Create new course </span> &nbsp; <i class="fa fa-plus"></i>
                </button>

            </div>
        </div>
    </div>

    <p>The courses below are available for you. Select the course you would like to view or edit.</p>
    <div class="row" *ngIf="namespaces && namespaces.length > 1">
        <div class="col-sm-6" *ngIf="hasGlobalNamespace()">
            <label class="padded-element"><input type='checkbox' [(ngModel)]="excludeFree"
                    (change)="excludeFreeChanged($event)" /> Exclude courses from free accounts</label>
        </div>
        <br />
    </div>
    <pagination-controller [hasPrevious]="currentPage > 0" [hasNext]="hasNext" (search)="setSearchFilter($event)"
        (next)="nextPage()" (previous)="previousPage()"
        (pageSizeChanged)="pageSizeChanged($event)"></pagination-controller>

    <busy-gears *ngIf="loading"></busy-gears>
    <br>
    <h5 class="row">
        <div class="col-xs-1"></div>
        <div class="col-xs-5">Course</div>
        <div class="col-xs-6">Namespace</div>

    </h5>

    <div *ngIf="courses && !loading">
        <div *ngIf="courses.length == 0">
            No courses were found.
        </div>

        <div *ngFor="let course of courses" class='course-item cms-navigator-item row display-flex-center'>
            <div class='col-xs-6'>
                <div class="dropdown">
                    <button class="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                        <span class="fa fa-book"></span> &nbsp;<i class="fa fa-chevron-down"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2"
                        *ngIf="canEditCourse(course) && actions && actions.length > 0">
                        <ng-container *ngFor="let action of actions">
                            <li *ngIf="!action.preCond || action.preCond(course)" (click)="action.callback(course)"
                                class='dropdown-entry'>
                                <a class="clickable">
                                    <span [ngClass]="action.iconClass"></span>
                                    <span class='dropdown-entry-label'>{{action.name}}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <span (click)="onCourseClick(course)">
                    {{course.name}}

                </span>
            </div>
            <div class="col-xs-6">
                <span *ngIf="course.namespace">{{course.namespace}}</span>
            </div>

        </div>
        <br />
        <pagination-controller [buttonsOnly]="true" [hasPrevious]="currentPage > 0" [hasNext]="hasNext"
            (next)="nextPage()" (previous)="previousPage()"></pagination-controller>


    </div>
</div>