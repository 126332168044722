import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';
import { compareVersion } from '../../authoring/util/sorting';
import { UserService } from '../../security/services/user.service';
import { ProfileSettings } from './profile.settings';

// Save the state of routes which have that attribute data: {sticky: true}
@Injectable()
export class AppProfileService {

    private profile: string = null;
    private settings: ProfileSettings;
    private managementConsoleUrl: string;

    constructor(
        protected http: HttpClient
    ) {
    }

    public getManagementConsoleUrl(): Promise<string> {
        return this.managementConsoleUrl != null
            ? Promise.resolve(this.managementConsoleUrl)
            : this.http.get('connect/management-console-url/',  {responseType:'text'})
            .toPromise()
            .then((url: string) => {
                this.managementConsoleUrl = url;
                return url;
            })
    }

    public getProfile(): Promise<string> {
        return this.profile
            ? Promise.resolve(this.profile)
            : this.http.get('connect/app-profile/',  {responseType:'text'})
                .toPromise()
                .then((profile: string) => {
                    this.profile = profile;
                    this.settings = new ProfileSettings(profile);
                    return profile
                })
    }

    public getSettings(): Promise<ProfileSettings> {
        if (!this.settings) {
            return this.getProfile()
                .then(() => this.settings);
        }
        return Promise.resolve(this.settings);
    }

    public getSetting(key: string): Promise<any> {
        return this.getSettings()
            .then((settings) => settings[key]);
    }
}