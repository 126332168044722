import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { CmsExerciseScript, CmsTopic, CmsSubject } from '../types/navigator.types';
import { ExerciseScriptService } from './exercise-script.service';
import { ToastrService } from 'ngx-toastr';
import { TopicService } from './topic.service';
import { SubjectService } from './subject.service';
import { SetTitleService } from '../../app/services/set-title.service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class SubjectResolverService extends SetTitleService implements Resolve<CmsSubject> {
    constructor(private subjectService: SubjectService, private toastr: ToastrService, private titleService: Title) { super(titleService) }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CmsSubject> {
        let id = route.params['id'];
        let courseId = route.params['courseId'];
        let publishId = route.params['publishId'];
        return this.subjectService.getSubject(id, publishId, courseId).toPromise().then((subject) => {
            let name = subject.rootFolder ? subject.course.name : subject.name;
            this.setTitle(name);
            return subject;
        });
    }
}

